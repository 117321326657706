import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { db, auth } from '../config/firebase';
import { doc, setDoc, updateDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth"; // Import authentication state listener
import Swal from 'sweetalert2';


// Sample Aralin data
const aralinData = [
    {   //ARALIN 111111111
        title: "Aralin 1: Kahulugan, Kasaysayan at Dahilan ng Globalisasyon",
        activities: {
            "Pagsusulat ng Sanaysay": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: '1. Sa loob ng 10 minuto, sumulat ng isang sanaysay na naglalahad ng iyong pananaw sa epekto ng globalisasyon sa kasaysayan ng ating bansa.  \n2. Gumamit ng 300-500 salita sa iyong sanaysay. \n3. Siguraduhing may malinaw na introduksyon, katawan, at konklusyon ang iyong sanaysay. Iwasan ang plagiarism; gumamit ng sariling pananalita.',
                format: "• Introduksyon - Ipakilala ang epekto ng globalisasyon sa kasaysayan ng bansa at ihayag ang iyong pangunahing pananaw o opinyon. \n• Katawan-  Magbigay ng malalim na pagsusuri sa mga epekto ng globalisasyon sa iba’t ibang aspeto ng kasaysayan (ekonomiya, kultura, teknolohiya, pulitika) gamit ang mga konkretong halimbawa na direktang sumusuporta sa iyong pananaw. Siguraduhing malinaw ang daloy ng mga ideya. \n• Konklusyon- Buodin ang mga pangunahing ideya at muling ipahayag ang iyong opinyon sa epekto ng globalisasyon. Magbigay ng makabuluhang panghuling pananaw. ",
                tableData: [
                    {
                        column1: "Kriteria ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malalim at komprehensibong pagsusuri sa epekto ng globalisasyon.",
                        column3: "Sapat na pagsusuri subalitt may ilang kulang.",
                        column4: "Mababaw ang pagsusuri at limitado ang mga ideya.",
                        column5: "Halos mali ang impormasyon."
                    },
                    {
                        column1: "Estruktura",
                        column2: "Malinaw at lohikal ang pagkakasunod-sunod ng ideya.",
                        column3: "Maayos ang pagkakasunod-sunod subalit may konting kalituhan.",
                        column4: "Medyo magulo ang daloy ng ideya.",
                        column5: "Walang malinaw na estruktura."
                    },
                    {
                        column1: "Paggamit ng Wika",
                        column2: "Wasto at angkop ang gamit ng wika; walang mali.",
                        column3: "May mga maliit na pagkakamali sa wika.",
                        column4: "Maraming mali sa wika subalit naiintindihan pa rin.",
                        column5: "Mali ang paggamit ng wika kaya mahirap maintindihan. "
                    },
                    {
                        column1: "Halimbawa",
                        column2: "Malinaw na naipahayag ang mga halimbawa na sumusuporta sa mga ideya.",
                        column3: "May mga halimbawa subalit hindi sapat.",
                        column4: "Kaunti ang mga halimbawa at hindi malinaw.",
                        column5: "Walang halimbawa o hindi nauugnay sa paksa."
                    },
                ],
            },
            "Debate": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon .",
                instructions: "1. Hatiin ang Grupo (Grupo A: Pabor sa mabuting epekto ng globalisasyon, (Grupo B: Pabor sa masamang epekto ng globalisasyon) \n2. Maglaan ng 15 minuto para pag-usapan ang mga argumento. \n• Opening Statements: 2 minuto bawat grupo. \n• Round of Arguments: 5 minuto bawat grupo. \n• Rebuttals: 2 minuto bawat grupo. Q&A Session: Mga tanong mula sa iba; 2 minuto para sa bawat grupo. \n• Closing Statements:2 minuto bawat grupo. \n• Pagboto:  Bumoto ang mga mag-aaral kung aling grupo ang kanilang sinusuportahan. \n• Pagninilay-nilay: Maglaan ng 10 minuto para sa mga saloobin at reflection.",
                tableData: [
                    {
                        column1: "Kriteria ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman ng Argumento",
                        column2: "Malinaw at makapangyarihang argumento na suportado ng sapat na ebidensya.",
                        column3: "Malinaw na argumento na may ilang ebidensya.",
                        column4: "Argumento ay naroroon subalit kulang sa ebidensya.",
                        column5: "Mahina ang argumento at walang sapat na ebidensya."
                    },
                    {
                        column1: "Kakayahang Makipag-usap",
                        column2: "Napakahusay na pagpapahayag; malinaw at epektibong komunikasyon.",
                        column3: "Magandang pagpapahayag; madaling intindihin.",
                        column4: "Katamtamang pagpapahayag; may mga pagkakataong hindi malinaw.",
                        column5: "Mahirap intidihin; kailangan ng higit pang pagsasanay."
                    },
                    {
                        column1: "Pagsagot sa Tanong",
                        column2: "Mabilis at angkop na sagot; malinaw na pagkakaunawa sa tanong.",
                        column3: "Angkop na sagot subalit may kakulangan sa detalye.",
                        column4: "Pagsagot sa tanong subalit may mga hindi nauunawaan.",
                        column5: "Walang maayos na sagot."
                    },
                    {
                        column1: "Pakikitungo sa Kalaban",
                        column2: "Magalang at may respeto sa mga argumento ng kalaban.",
                        column3: "Magalang ngunit may mga pagkakataong nawawalan ng respeto.",
                        column4: "Kakaunting respeto; may ilang hindi magandang pakikitungo.",
                        column5: "Walang respeto sa kalaban; labis na agresibo."
                    },
                ],
            },
            "Research Project on Globalization": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "1. Pumili ng isang aspeto ng globalisasyon: ekonomiko, kultural, o politikal. Gumamit ng hindi bababa sa limang (5) pinagkukunang impormasyon, kabilang ang mga libro, artikulo, at online na mapagkukunan.  \n2. Isulat ang iyong pananaliksik sa 1000-1500 salita. \n3. Ipasa ang research paper sa itinakdang Araw.",
                format: "Pamagat \n• Introduksyon - Ipakilala ang napiling aspeto ng globalisasyon (ekonomiko, kultural, o politikal). Ibigay ang layunin ng pananaliksik at ang kahalagahan ng paksa. Magbigay ng maikling overview kung bakit mahalaga ang pag-aaral ng globalisasyon sa kasalukuyang panahon. \n• Katawan \n Seksyon 1: Paglalahad ng mga Epekto - I-discuss ang mga pangunahing epekto ng napiling aspeto ng globalisasyon (halimbawa: epekto ng ekonomiko, epekto sa kultura, o epekto sa politika). \nSeksyon 2: Mga Halimbawa at Pag-aaral - Magbigay ng mga konkretong halimbawa ng globalisasyon sa konteksto ng napiling aspeto.  I-eksplika ang mga case studies o tunay na halimbawa ng epekto ng globalisasyon. \nSeksyon 3: Mga Pananaw mula sa Iba't Ibang Sanggunian - Ibigay ang iba't ibang pananaw at opinyon mula sa mga eksperto o mga pinagkukunang impormasyon tulad ng mga artikulo, libro, at online na mapagkukunan. \n• Konklusyon- Ibuod ang mga pangunahing natuklasan ng pananaliksik.  Ibigay ang iyong mga huling opinyon o rekomendasyon kaugnay ng epekto ng globalisasyon sa napiling aspeto. Ibigay ang kahalagahan ng mga natuklasan sa kasalukuyan at hinaharap. \n• Talaan ng mga Sanggunian - Magbigay ng hindi bababa sa limang (5) sanggunian mula sa mga aklat, artikulo, at online na mapagkukunan. Siguraduhing tama ang pagsipi ng mga sanggunian gamit ang tamang format (halimbawa: APA, MLA, o Chicago).",
                tableData: [
                    {
                        column1: "Kriteria ",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Komprehensibo at malalim ang nilalaman ng pananaliksik; naglalaman ng sapat na impormasyon at detalyado ang pagsusuri.",
                        column3: "Sapat ang nilalaman subalit may kaunting kakulangan sa lalim; naglalaman ng mga pangunahing ideya.",
                        column4: "Limitado ang nilalaman; maraming hindi sapat na impormasyon at walang sapat na pagsusuri.",
                        column5: "Karamihan sa nilalaman ay hindi nauugnay sa paksa. "
                    },
                    {
                        column1: "Estruktura",
                        column2: "Malinaw at lohikal ang pagkakaayos ng impormasyon; may mahusay na introduksyon, katawan, at konklusyon.",
                        column3: "Maayos ang pagkakaayos subalit may konting kalituhan sa daloy ng ideya.",
                        column4: "Medyo magulo ang pagkakaayos; may mga parte na mahirap sundan.",
                        column5: "Walang malinaw na estruktura; mahirap sundan ang daloy ng ideya."
                    },
                    {
                        column1: "Paggamit ng Wika",
                        column2: "Wasto at angkop ang gamit ng wika.",
                        column3: "May mga maliit na pagkakamali sa wika, subalit naiintindihan pa rin.",
                        column4: "Maraming pagkakamali sa wika.",
                        column5: "Napakaraming mali sa wika; mahirap intidihin ang mga ideya."
                    },
                    {
                        column1: "Sanggunian",
                        column2: "Gumamit ng hindi bababa sa limang (5) wastong sanggunian at tama ang pagsipi.",
                        column3: "Gumamit ng sapat na bilang ng sanggunian, subalit may kaunting pagkakamali sa pagsipi.",
                        column4: "Kaunti ang mga sanggunian o maraming mali sa pagsipi; hindi nakatuon sa mga wastong pinagkukunan.",
                        column5: "Walang wastong sanggunian o hindi maayos ang mga ito."
                    },
                ],
            },
            "Poster Making": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "1. Sa loob ng 15 minutes, gumawa ng poster patungkol sa globalisasyon. Gumamit ng 1/8 white Cartolina para sa iyong poster. \n2. Tiyakin na mayroong malinaw na pamagat at mga pangunahing konsepto na nauugnay sa globalisasyon. \n3. Maaring gumamit ng ilustrasyon, diagram, larawan o sariling guhit upang mas maipaliwanag ang iyong mensahe gamit ang mga art materials.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Kreatibidad at disenyo",
                        column2: "Napaka malikhaing disenyo at mahusay ang pagkakagawa.",
                        column3: "Malikhaing disenyo at kaaya-aya.",
                        column4: "Medyo malikhaing disenyo ngunit may mga kakulangan",
                        column5: "Hindi malikhaing disenyo at may mga kamalian"
                    },
                    {
                        column1: "Pagkaangkop sa paksa",
                        column2: "Lubos na naaangkop ang disenyo sa paksa",
                        column3: "Naaangkop ang disenyo sa paksa.",
                        column4: "Medyo naaangkop ang disenyo sa paksa",
                        column5: "Hindi naaangkop ang disenyo sa paksa"
                    },
                    {
                        column1: "Mensaheng nais iparating",
                        column2: "Napakalinaw at epektibo ang mensaheng nais iparating.",
                        column3: "Malinaw ang mensahe.",
                        column4: "Medyo malinaw ang mensahe",
                        column5: "Hindi malinaw ang mensahe."
                    },
                    {
                        column1: "Paggamit ng kulay at espasyo",
                        column2: "Mahusay na paggamit ng kulay at espasyo.",
                        column3: "Sapat ang paggamit ng kulay at espasyo.",
                        column4: "Limitado ang paggamit ng kulay at espasyo.",
                        column5: "Walang tamang paggamit ng kulay at espasyo."
                    },
                ],
            },
            "Oral Presentation": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "1. Gumawa ng isang oral presentation na naglalarawan ng kahalagahan ng globalisasyon sa kasalukuyan.  \n2. Maaari itong gumamit ng visual aids tulad ng slides, larawan, o video upang mas maipaliwanag ang mensahe. Ihanda ang iyong presentasyon sa loob ng 5-7 minuto. \n3. Tiyaking may malinaw na estruktura: introduksyon, katawan, at konklusyon. \n4. Gumamit ng mga halimbawa at datos upang suportahan ang iyong mga punto. \n5. Magsagawa ng isang maikling tanungan pagkatapos ng iyong presentasyon upang sagutin ang mga tanong ng mga kaklase.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Lalim ng kaalaman",
                        column2: "Lubos na malalim at komprehensibo ang kaalaman.",
                        column3: "Malalim at sapat ang kaalaman.",
                        column4: "Katamtaman ang kaalaman.",
                        column5: "Hindi sapat ang kaalaman."
                    },
                    {
                        column1: "Kasanayan sa pagsasalita",
                        column2: "Mahusay at malinaw ang pagpapahayag ng ideya.",
                        column3: "Sapat na malinaw ang pagsasalita.",
                        column4: "Medyo malinaw ang pagsasalita ngunit may kakulangan.",
                        column5: "Hindi maayos ang pagsasalita."
                    },
                    {
                        column1: "Organisasyon ng ideya",
                        column2: "Lohikal at malinaw ang pagkakaayos ng mga ideya.",
                        column3: "Malinaw ang pagkakaayos ng mga ideya.",
                        column4: "Medyo magulo ang pagkakaayos ng mga ideya",
                        column5: "Hindi organisado at walang lohika ang mga ideya."
                    },
                    {
                        column1: "Paggamit ng visual aid",
                        column2: "Mahusay na paggamit ng visual aid bilang suporta sa presentasyon.",
                        column3: "Sapat ang paggamit ng visual aid.",
                        column4: "Limitado ang paggamit ng visual aid.",
                        column5: "Walang paggamit ng visual aid."
                    },
                ],
            }
        },
    },
    { //ARALIN 22222222222
        title: "Aralin 2: Dimensiyon at Epekto ng Globalisasyon",
        activities: {
            "Pagsulat ng Posisyong Papel": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "1. Sumulat ng isang posisyong papel na hindi bababa sa 600 na salita, naglalahad ng iyong opinyon hinggil sa epekto ng globalisasyon sa ekonomiya.  \n2. Tiyakin na naglalaman ito ng mga pangunahing argumento at mga konkretong halimbawa upang suportahan ang iyong pananaw. \n3. I-submit ang iyong posisyong papel sa takdang araw.",
                format: "• Panimula - Ipakilala ang globalisasyon at ang epekto nito sa ekonomiya.Ipahayag ang iyong posisyon tungkol dito. \n• Katawan - Ilahad ang mga pangunahing argumento na sumusuporta sa iyong posisyon, kasama ang mga halimbawa o ebidensya. \n• Konklusyon - Buod ng mga argumento. Ulitin ang iyong posisyon at bigyang-diin ang kahalagahan ng iyong pananaw.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Napaka-komprehensibong pagtalakay sa posisyon at gumagamit ng konkretong halimbawa at mga sanggunian.",
                        column3: "Sapat ang nilalaman subalit may kaunting kakulangan sa detalye; may mga pangunahing ideya.",
                        column4: "Limitado ang nilalaman; mababaw ang pagtalakay at kulang sa mga halimbawa.",
                        column5: "Halos walang laman o hindi nauugnay sa paksa; walang mga halimbawa."
                    },
                    {
                        column1: "Pagsusuri at Argumentasyon",
                        column2: "Malinaw at epektibong nailahad ang mga argumento na sumusuporta sa posisyon; malalim ang pag-unawa sa paksa.",
                        column3: "Sapat ang pagsusuri at argumento subalit may kaunting kakulangan sa lalim.",
                        column4: "Limitado ang pagsusuri at argumento; hindi malinaw ang pagkakaunawa sa paksa.",
                        column5: "Walang nailahad na argumento; hindi nauugnay sa paksa."
                    },
                    {
                        column1: "Estruktura",
                        column2: "Maayos ang pagkakasunod-sunod ng mga ideya; malinaw ang pagkakaayos ng posisyong papel.",
                        column3: "May kaunting kalituhan sa daloy ng ideya; medyo maayos ang pagkakaayos.",
                        column4: "Magulo ang pagkakaayos; may mga bahagi na mahirap sundan.",
                        column5: "Walang malinaw na estruktura; mahirap sundan ang daloy ng posisyong papel."
                    },
                    {
                        column1: "Paggamit ng Wika",
                        column2: "Wasto at angkop ang gamit ng wika; walang mali sa gramatika at baybay.",
                        column3: "May mga maliit na pagkakamali sa wika, subalit naiintindihan pa rin.",
                        column4: "Maraming pagkakamali sa wika; nakakaapekto sa pagkaunawa ng mensahe.",
                        column5: "Napakaraming mali sa wika; mahirap intidihin ang mga ideya."
                    },
                ],
            },
            "Case Study Analysis": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "1. Gumawa ng isang pagsusuri sa isang partikular na kaso ng globalisasyon, na naglalarawan ng mga epekto nito sa isang tiyak na sektor ng lipunan (halimbawa: edukasyon, kalusugan, o ekonomiya). Hindi bababa sa 800 salita. \n2. Tiyakin na nailahad ang mga konkretong halimbawa at datos na sumusuporta sa iyong pagsusuri. \n3. I-submit ang iyong ulat sa takdang araw at maging handa na ipresenta ang iyong mga natuklasan sa klase sa loob ng 7-10 minuto.",
                format: "• Panimula - Ipakilala ang kaso ng globalisasyon at ang partikular na sektor ng lipunan na apektado (hal. edukasyon, kalusugan, ekonomiya). Ipahayag ang layunin ng pagsusuri. \n• Paglalarawan ng Kaso- Magbigay ng maikling background ng kaso.Ilahad ang mga pangunahing detalye at mga pangunahing kaganapan na may kaugnayan sa kaso. \n• Pagsusuri - Suriin ang epekto ng globalisasyon sa napiling sektor ng lipunan. Magbigay ng mga konkretong halimbawa at datos na sumusuporta sa pagsusuri. \n• Konklusyon - Ibuod ang mga natuklasan. Ipahayag ang mga mahahalagang punto tungkol sa epekto ng globalisasyon batay sa kaso.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Pagsusuri sa kaso",
                        column2: "Lubos na komprehensibo at malalim ang pagsusuri sa kaso, na nagpapakita ng mga detalyadong ebidensya at pagsusuri sa epekto ng globalisasyon sa sektor.",
                        column3: "Malalim at sapat ang pagsusuri sa kaso, subalit may ilang aspeto na maaaring mapalawak pa.",
                        column4: "Medyo mababaw ang pagsusuri sa kaso, at limitado ang mga ebidensya at pagsusuri sa epekto ng globalisasyon.",
                        column5: "Kulang o halos walang pagsusuri sa kaso at walang malinaw na pagsusuri sa epekto ng globalisasyon. "
                    },
                    {
                        column1: "Lohikal na pag-aanalisa",
                        column2: "Napakalinaw at lohikal ang pagkakalahad ng mga ideya at argumento, na mayroong mahusay na pagkakaugnay ng mga datos at pagsusuri.",
                        column3: "Malinaw at lohikal ang pag-aanalisa, subalit may ilang bahagi na  hindi naunawaan ang koneksyon ng mga datos at pagsusuri. ",
                        column4: "Malinaw at lohikal ang pag-aanalisa, subalit may ilang bahagi na Hindi naunawaan ang koneksyon ng mga datos at pagsusuri.",
                        column5: "Walang malinaw na lohikal na pagkakaugnay ng mga ideya."
                    },
                    {
                        column1: "Kalinawan ng presentasyon",
                        column2: "Napakalinaw, organisado, at madaling maunawaan ang presentasyon; mahusay na naipapaliwanag ang bawat bahagi ng pagsusuri.",
                        column3: "Malinaw at organisado ang presentasyon subalit may ilang bahagi na maaaring gawing mas malinaw pa.",
                        column4: "Hindi  organisado ang presentasyon, kaya medyo mahirap unawain ang ibang bahagi ng pagsusuri.",
                        column5: "Hindi malinaw and presentasyon at kulang sa organisasyon."
                    },
                    {
                        column1: "Paggamit ng tamang datos",
                        column2: "Lubos na wasto at angkop ang paggamit ng mga datos mula sa mapagkakatiwalaang mga sanggunian, na nagpapalakas sa pagsusuri ng kaso.",
                        column3: "Wasto at sapat ang paggamit ng mga datos, subalit may ilang mga datos na maaaring mas suportahan pa ng karagdagang ebidensya mula sa mga sanggunian.",
                        column4: "Ilan lamang ang wasto o angkop na datos, at hindi sapat na nasuportahan ng mga ebidensya mula sa mapagkakatiwalaang mga sanggunian ang pagsusuri.",
                        column5: "Hindi angkop ang paggamit ng mga datos at  walang sapat na ebidensya sa mga sanggunian na sumusuporta sa pagsusuri."
                    },
                ],
            },
            "Multimedia Presentation": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "1. Lumikha ng isang multimedia presentation (PowerPoint, Prezi, o iba pang katulad na software) na naglalarawan ng mga epekto ng globalisasyon sa kultura, edukasyon, at komunikasyon. \n2. Gumawa ng isang multimedia presentation na hindi bababa sa 10 slides. Tiyakin na ang bawat slide ay naglalaman ng mga pangunahing ideya, mga larawan o grapiko, at mga halimbawa. \n3. Ang iyong presentasyon ay dapat na malinaw at madaling maintindihan, at ipresenta ito sa klase sa loob ng 10-15 minuto.",
                format: "1. Pamagat na Slide \n• Pamagat ng Presentasyon \n• Pangalan ng Tagapagsalita at Petsa \n2. Slide 1-2: \n• Panimula- layunin ng Presentasyon:  \n• Maikling Background: Pangkalahatang ideya ng globalisasyon. \n3. Side 3-4: Epekto ng Globalisasyon sa Kultura \n• Pangunahing Ideya: \n• Mga Halimbawa: \n• Mga Visual Aid: Larawan ng mga kultural na elemento mula sa iba’t ibang bansa. \n4. Slide 5-6: Epekto ng Globalisasyon sa Edukasyon \n• Mga Halimbawa \n• Mga Visual Aid: Grapiko o larawan na nagpapakita ng online classes o cultural exchange. \n5. Slide 7-8: Epekto ng Globalisasyon sa Komunikasyon \n• Pangunahing Ideya: \n• Mga Halimbawa: \n• Mga Visual Aid: \n6. Slide 9: Pagsusuri at Mga \n7. Pangunahing Puntos- Buod ng Epekto: \n• Insight: isang maikling repleksyon o tanong para sa klase (e.g., “Ano ang mga hamon ng globalisasyon?”) \n8. Slide 10: Konklusyon \n• Pangwakas na Pahayag: Buod ng iyong natutunan at mahahalagang takeaway.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Napaka-komprehensibong pagtalakay sa mga epekto at gumagamit ng konkretong halimbawa at mga sanggunian.",
                        column3: "Sapat ang nilalaman subalit may kaunting kakulangan sa detalye; may mga pangunahing ideya.",
                        column4: "Limitado ang nilalaman; mababaw ang pagtalakay at kulang sa mga halimbawa.",
                        column5: "Halos walang laman o hindi nauugnay sa paksa; walang mga halimbawa."
                    },
                    {
                        column1: "Kreatibidad",
                        column2: "Napaka-malikhaing presentasyon; mahusay ang paggamit ng mga visual aids at multimedia elements.",
                        column3: "Mahusay ang paggamit ng mga visual aids; medyo malikhaing presentasyon. ",
                        column4: "Limitado ang gamit ng visual aids; hindi gaanong malikhain.",
                        column5: "Walang gamit ng visual aids o hindi kaakit-akit ang presentasyon."
                    },
                    {
                        column1: "Estruktura at Daloy",
                        column2: "Maayos ang pagkakasunod-sunod ng mga ideya; malinaw ang daloy ng presentasyon.",
                        column3: "May kaunting kalituhan sa daloy ng ideya; medyo maayos ang pagkakaayos.",
                        column4: "Magulo ang pagkakaayos; may mga bahagi na mahirap sundan.",
                        column5: "Walang malinaw na estruktura at mahirap sundan ang daloy ng presentasyon."
                    },
                    {
                        column1: "Paggamit ng Wika",
                        column2: "Wasto at angkop ang gamit ng wika at walang mali sa gramatika at baybay.",
                        column3: "May mga maliit na pagkakamali sa wika, ngunit naiintindihan pa rin.",
                        column4: "Maraming pagkakamali sa wika at nakakaapekto sa pagkaunawa ng mensahe.",
                        column5: "Napakaraming mali sa wika at mahirap intidihin ang mga ideya."
                    },
                ],
            },
            "Debate": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon.",
                instructions: "1. Makipagdebate sa isang grupo tungkol sa mga positibo at negatibong epekto ng globalisasyon sa kalikasan. \n2. Ang bawat grupo ay dapat maghanda ng mga argumento at ebidensya upang suportahan ang kanilang panig. Hindi bababa sa 5 pangunahing argumento na susuporta sa kanilang panig. \n3. Ang debate ay dapat tumagal ng 20 minuto, kung saan ang bawat grupo ay magkakaroon ng pagkakataon na ipahayag ang kanilang mga argumento at sagutin ang mga tanong mula sa kalaban.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Napaka-komprehensibong pagtalakay sa mga epekto,  gumagamit ng konkretong halimbawa at mga sanggunian.",
                        column3: "Sapat ang nilalaman subalit may kaunting kakulangan sa detalye.",
                        column4: "Limitado ang nilalaman, mababaw ang pagtalakay at kulang sa mga halimbawa.",
                        column5: "Halos walang laman o hindi nauugnay sa paksa."
                    },
                    {
                        column1: "Argumentasyon",
                        column2: "Malinaw at epektibong nailahad ang mga argumento; may malalim na pag-unawa sa paksa.",
                        column3: "Sapat ang argumento subalit may kaunting kakulangan sa lalim.",
                        column4: "Limitado ang argumento; hindi malinaw ang pagkakaunawa sa epekto ng globalisasyon.",
                        column5: "Walang nailahad na argumento; hindi nauugnay sa paksa."
                    },
                    {
                        column1: "Komunikasyon",
                        column2: "Maayos ang paraan ng pagpapahayag; tiyak at epektibo ang komunikasyon.",
                        column3: "Magandang paraan ng pagpapahayag; may kaunting kakulangan sa klaridad.",
                        column4: "Limitado ang kakayahan sa komunikasyon.",
                        column5: "Mahirap intidihin ang mga ideya."
                    },
                    {
                        column1: "Pakikipag-ugnayan",
                        column2: "Aktibong nakilahok sa debate; epektibong nakipag-ugnayan sa mga kasamahan at kalaban.",
                        column3: "Sapat ang pakikilahok ngunit may ilang pagkakataon na hindi epektibo ang pakikipag ugnayan.",
                        column4: "Limitado ang pakikilahok.",
                        column5: "Walang aktibong pakikilahok; hindi nag-ambag sa debate."
                    },
                ],
            },
            "Infographic Creation": {
                objectives: "Nasusuri ang dahilan, Dimensyon at epekto ng globalisasyon..",
                instructions: "1. Lumikha ng isang infographic na naglalarawan ng mga pangunahing epekto ng globalisasyon sa mga aspeto ng ekonomiya, lipunan, at kalikasan. \n2. Gumamit ng mga online tools o software upang lumikha ng isang infographic na hindi bababa sa isang page. \n3. Tiyakin na ang iyong infographic ay may malinaw na layout, mga visual na elemento, at mga konkretong datos o impormasyon. \n4. I-submit ang iyong infographic sa takdang araw.",
                tableData: [
                    {
                        column1: "Kriteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangang Pagbutihin (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Napaka-komprehensibong pagtalakay sa mga epekto; gumagamit ng konkretong halimbawa at datos.",
                        column3: "Sapat ang nilalaman subalit may kaunting kakulangan sa detalye.",
                        column4: "Limitado ang nilalaman at kulang sa mga halimbawa.",
                        column5: "Halos walang laman at walang mga halimbawa."
                    },
                    {
                        column1: "Kreatibidad at Disenyo",
                        column2: "Napaka-malikhaing disenyo; mahusay ang paggamit ng kulay at visual elements.",
                        column3: "Magandang disenyo subalitt may ilang kakulangan sa visual appeal.",
                        column4: "Limitado ang creatibity sa disenyo; hindi gaanong kaakit-akit.",
                        column5: "Walang sapat na effort sa disenyo; mahirap intidihin ang infographic."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Maayos ang pagkakasunod-sunod ng mga ideya; malinaw ang daloy ng impormasyon.",
                        column3: "May kaunting kalituhan sa daloy ng ideya; medyo maayos ang pagkakaayos.",
                        column4: "Magulo ang pagkakaayos; may mga bahagi na mahirap sundan.",
                        column5: "Walang malinaw na estruktura; mahirap sundan ang daloy ng impormasyon."
                    },
                    {
                        column1: "Paggamit ng Wika",
                        column2: "Wasto at angkop ang gamit ng wika; walang mali sa gramatika at baybay.",
                        column3: "May mga maliit na pagkakamali sa wika, subalit naiintindihan pa rin.",
                        column4: "Maraming pagkakamali sa wika; nakakaapekto sa pagkaunawa ng mensahe.",
                        column5: "Napakaraming mali sa wika; mahirap intidihin ang mga ideya."
                    },
                ],
            },
        },
    },
    { //ARALIN 33333333333
        title: "Aralin 3: Kalagayan at Suliranin ng Paggawa sa Bansa sa Konteksto ng Globalisasyon",
        activities: {
            '“Boses ng Manggagawa” - (Debate)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: "1. Hahatiin ang klase sa dalawang grupo: Grupo A at Grupo B. \n2. Ang bawat grupo ay pipili ng limang kinatawan na sasabak sa tagisan ng debate. \n3. Magbigay ng tatlong minuto sa mga grupo upang pumili ng tiyak na tema para sa kanilang argumento, na maaaring pagsuporta o pagtutol sa kalagayan ng paggawa. \n4. Bigyan ang bawat grupo ng sampung minuto upang maghanda ng kanilang argumento, kung saan dapat nilang talakayin ang mga pangunahing punto na kanilang ipapahayag sa debate. \n5. Hikayatin ang mga estudyante na mag-research online o sa kanilang mga libro ng impormasyon na susuporta sa kanilang mga argumento sa loob ng limang minuto. \n6. Isagawa ang debate na tatagal ng dalawampung minuto, kung saan bibigyan ng dalawang minuto ang bawat grupo upang ipahayag ang kanilang argumento. \n7. Pagkatapos ng bawat presentasyon, bigyan ng isa hanggang dalawang minuto ang bawat grupo para sa rebuttal. \n8. Sa pagtatapos ng debate, maglaan ng limang minuto upang talakayin ang mga natutunan mula sa aktibidad.",
                format: "• Paghahati ng Grupo - Sa loob ng 3 minuto, hatiin ang klase sa Grupo A (Pagsuporta) at Grupo B (Pagtutol). \n• Pagpili ng Tema - Sa loob ng 3 minuto, ang bawat grupo ay pipili ng tema: Pagsuporta o Pagtutol sa kalagayan ng paggawa. \n• Paghahanda - Sa loob ng 10 minuto, ang bawat grupo ay maghahanda ng mga argumento at mag-research ng impormasyon. \n• Pagpapahayag ng Panig - Sa loob ng 2 minuto, and bawat grupo ay magbibigay ng pangunahing argumento. \n• Rebuttal - Sa loob ng 1-2 minuto, ang bawat grupo ay magbibigay ng pangunahing argumento. \n• Pagtalakay ng Natutunan - Sa loob ng 5 minuto, magkakaroon ng talakayan tungkol sa mga natutunan mula sa debate.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan",
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Kumpleto at tama ang impormasyon, maliwanag at maayos ang pagbigkas, malakas at nakakaengganyo ang argumento, may sapat na ebidensya.",
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Kumpleto ngunit may kaunting mali sa impormasyon, malinaw ngunit may mga pagkakamali sa pagbigkas, mahusay ngunit kulang sa pwersa, may sapat na ebidensya.",
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Hindi kumpleto, may ilang hindi tama sa impormasyon, mahirap intidihin, kailangan ng higit pang ebidensya.",
                    },
                    {
                        column1: "Kailangan ng Pagsasanay (1)",
                        column2: "Hindi kumpleto at mali ang impormasyon, hindi maliwanag, hindi maayos ang pagbigkas, mahina at walang suporta.",
                    }
                ],
            },
            '“Sikaping I-Rescue” - (Role Play)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: "1. Hatiin ang klase sa mga grupo ng 5-6 na mag-aaral. \n2. Bawat grupo ay pipili ng isang suliranin ng mga manggagawa na kanilang gaganapan sa role-play. \n3. Bibigyan ng 5 minuto ang mga mag-aaral upang magplano at maghanda ng kanilang role-play, kung saan dapat silang magtalaga ng mga tungkulin at bumuo ng isang kwento na may solusyon sa napiling suliranin. \n4. Isasagawa ang role-play ng bawat grupo sa harap ng klase, at tatagal ito ng 5 minuto bawat grupo. \n5. Matapos ang bawat performance, bibigyan ng 3-5 minuto ang klase upang magbigay ng feedback at mga tanong.",
                tableData: [
                    {
                        column1: "Criteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Orihinal at makabago ang performance.",
                        column3: "Magandang ideya ngunit hindi ganap na natupad.",
                        column4: "May ilang orihinal na ideya ngunit hindi matibay.",
                        column5: "Kulang ang orihinal na ideya."
                    },
                    {
                        column1: "Tema",
                        column2: "Nasunod ang tema ng suliranin.",
                        column3: "Nakalagay ang tema ngunit hindi ganap na nakatuon. ",
                        column4: "Bahagyang nabanggit ang tema.",
                        column5: "Hindi nasunod ang tema."
                    },
                    {
                        column1: "Kaugnayan/Kaangkupan",
                        column2: "Maayos ang daloy ng kwento at ideya.",
                        column3: "Magandang daloy ngunit may ilang bahagi na magulo.",
                        column4: "Kailangan ng higit pang pag-organisa.",
                        column5: "Magulo ang daloy ng kwento at ideya."
                    },
                    {
                        column1: "Kooperasyon",
                        column2: "Nagtulungan ang lahat ng kasapi at mahusay ang pagganap.",
                        column3: "May pagtutulungan ngunit hindi lahat ay nakilahok.",
                        column4: "May ilang hindi nakilahok.",
                        column5: "Walang pagtutulungan sa grupo."
                    },
                ],
            },
            '“Isang Pagsusuri” - (Case Study)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: "1. Hahatiin ang mga mag-aaral sa anim na grupo. \n2. Bibigyan sila ng sapat na panahon upang pumili ng isang tiyak na kaso o isyu na may kaugnayan sa suliranin ng paggawa.\n3. Magsasagawa ang mga mag-aaral ng pagsusuri sa kanilang napiling kaso gamit ang mga resources mula sa mga libro o online. \n4. Magbibigay ang guro ng sapat na panahon para sa pagsusulat ng tatlong-pahinang pagsusuri, na may introduksyon, katawan, at konklusyon. \n5. Sa pagpaplano ng presentasyon, may kalayaan ang mga mag-aaral na magdisenyo ng kanilang presentasyon at maaaring gumamit ng PowerPoint o iba pang visual aids. \n6. Ang bawat grupo ay kinakailangang ipresenta ang kanilang pagsusuri sa loob ng limang minuto. \n7. Maaaring piliin ng grupo ang kanilang kinatawan na mag-uulat ng kanilang ginawa. \n8. Pagkatapos ng presentasyon, maglalaan ng limang minuto para sa tanungan at sagutan.",
                format: "Pamagat ng Kaso/Isyu   \nI. Introduksyon \n• Layunin ng pagsusuri \n• Pangkalahatang ideya ng isyu \n• Kahalagahan ng isyu sa sektor ng paggawa \nII. Katawan \n• Paglalarawan ng kaso/Isyu  \n• Mga sanhi at epekto \n• Mga epekto sa manggagawa/kapakanan ng mga tao \n• Mga posibleng solusyon o rekomendasyon   \n• Pagtalakay sa mga mapagkukunan (Mga libro, artikulo, o online resources) \nIII. Konklusyon \n• Buod ng pagsusuri \n• Pagtatapos na obserbasyon \n• Mga rekomendasyon para sa paglutas ng isyu \nIV. \n(Ilahad ang lahat ng ginamit na mga sanggunian mula sa libro, artikulo, website, o iba pang pinagmulan ng impormasyon.)",
                tableData: [
                    {
                        column1: "Criteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang datos.",
                        column3: "Kumpleto ngunit may kulang sa detalye.",
                        column4: "Kailangan ng higit pang datos.",
                        column5: "Wala o napaka-limited na datos."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Maayos ang pagkakasunod-sunod ng ideya.",
                        column3: "Magandang daloy ngunit may ilang bahagi na magulo.",
                        column4: "Kailangan ng higit pang pag-organisa.",
                        column5: "Magulo ang daloy ng ideya."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Malalim ang pagsusuri sa datos.",
                        column3: "Makabuluhang pagsusuri ngunit may mga pagkukulang.",
                        column4: "Kailangan ng higit pang kritikal na pagsusuri.",
                        column5: "Walang sapat na pagsusuri."
                    },
                    {
                        column1: "Sanggunian",
                        column2: "Sapat at tama ang mga sanggunian.",
                        column3: "May mga sanggunian ngunit hindi sapat.",
                        column4: "Kailangan ng higit pang sanggunian.",
                        column5: "Walang sanggunian."
                    },
                ],
            },
            '“Manggagawa sa Bansa” - (Documentary Video)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: "1. Hatiin ang klase sa mga grupo ng 4-5 na mag-aaral. \n2. Bigyan ang bawat grupo ng sapat na panahon upang pumili ng partikular na suliranin ng mga manggagawa na kanilang gawing paksa ng documentary. \n3. Magsaliksik at mangalap ng mga impormasyon at materyales ang bawat grupo. \n4. Magsulat ng script para sa kanilang documentary. \n5. Siguraduhin na ang video ay hindi lalampas ng 15 minuto. \n6. May kalayaan ang mga mag-aaral na i-edit ang kanilang nakuha upang mas maging maganda ito. \n7. Ipresenta ng mga mag-aaral ang kanilang documentary video sa klase. \n8. Pagkatapos ng bawat video, bibigyan ang klase ng 2 minuto upang magbigay ng feedback.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan",
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Komprehensibo at makabuluhan ang nilalaman, mataas ang kalidad ng video, at malinaw ang layunin ng documentary.",
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Mahusay ang nilalaman ngunit may ilang pagkukulang sa kalidad ng video.",
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Kailangan ng mas malalim na nilalaman at mas magandang kalidad ng video.",
                    },
                    {
                        column1: "Kailangan ng Pagsasanay (1)",
                        column2: "Walang sapat na nilalaman, mababa ang kalidad ng video, at hindi malinaw ang layunin.",
                    },
                ],
            },
            '"Mga Manggagawa sa Makabagong Panahon” - (Research Paper)': {
                objectives: "Naipapaliwanag ang kalagayan at suliranin sa paggawa sa bansa.",
                instructions: "1. Bigyan ang mga mag-aaral ng sapat na panahon upang pumili ng tiyak na pagbabago sa kalagayan ng mga manggagawa. \n2. Maglaan sila ng oras para magsaliksik at mangalap ng impormasyon. \n3. Matapos ang pananaliksik, bibigyan ang mga mag-aaral ng sapat na panahon para sa pagsusulat ng kanilang 4-pahinang papel. \n4. Maglalaan din ng oras para sa pagpaplano ng kanilang presentasyon. \n5. Sa huli, ipapresenta ng bawat mag-aaral ang kanilang research paper sa loob ng 10 minuto. \n6. Pagkatapos ng presentasyon, maglalaan ng 5 minuto para sa tanungan at sagutan.",
                format: "Pamagat ng Pananaliksik: (Punan ang pamagat ng iyong pananaliksik) \nI. Panimula \n• Pangkalahatang Pagsusuri ng Paksa \n• Layunin ng Pag-aaral \n• Mga Tanong sa Pananaliksik \nII. Paglalahad ng mga Kaugnay na Literatura at Mga Pag-aaral \nIII.  Metodolohiya \n• Pamamaraan ng Pananaliksik \n• Mga Instrumento sa Pagkolekta ng Datos \n• Sample na Populasyon (Kung may applicable) \nIV. Resulta at Pag-aanalisa \n• Paglalahad ng mga Natuklasan \n• Pag-aanalisa ng mga Resulta \nV. Kongklusyon at Rekomendasyon \nVI. Mga Sanggunian",
                tableData: [
                    {
                        column1: "Criteria",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang datos.",
                        column3: "Kumpleto ngunit may kulang sa detalye.",
                        column4: "Kailangan ng higit pang datos.",
                        column5: "Wala o napaka-limited na datos."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Maayos ang pagkakasunod-sunod ng ideya.",
                        column3: "Magandang daloy ngunit may ilang bahagi na magulo.",
                        column4: "Kailangan ng higit pang pag-organisa.",
                        column5: "Magulo ang daloy ng ideya."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Malalim ang pagsusuri sa datos.",
                        column3: "Makabuluhang pagsusuri ngunit may mga pagkukulang.",
                        column4: "Kailangan ng higit pang kritikal na pagsusuri.",
                        column5: "Walang sapat na pagsusuri."
                    },
                    {
                        column1: "Sanggunian",
                        column2: "Sapat at tama ang mga sanggunian.",
                        column3: "May mga sanggunian ngunit hindi sapat.",
                        column4: "Kailangan ng higit pang sanggunian.",
                        column5: "Walang sanggunian."
                    },
                ],
            },
        },
    },
    { //ARALIN 4444444444
        title: "Aralin 4: Pagtugon sa Isyu sa Paggawa sa Bansa",
        activities: {
            '“Pagsusuri ng Kalagayan ng mga Manggagawa”': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "1. Maglaan ng 15 minuto upang magsaliksik tungkol sa kasalukuyang isyu sa paggawa sa bansa. \n2. Tiyaking suriin ang mga artikulo, ulat, at opinyon mula sa mga eksperto. \n3. Isulat ang mga pangunahing punto at ideya sa iyong kuwaderno. \n4. Gumawa ng isang field survey tungkol sa kalagayan ng mga manggagawa sa inyong komunidad. \n5. I-report ang inyong mga natuklasan sa klase.",
                tableData: [
                    {
                        column1: "Krayterya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang impormasyon; nakatulong sa pag-unawa ng isyu.",
                        column3: "Magandang impormasyon, ngunit may kulang sa detalye.",
                        column4: "Nakakabit, ngunit may kakulangan sa impormasyon.",
                        column5: "Hindi malinaw; mahirap intidihin ang mensahe."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Malalim at kritikal na pagsusuri ng mga datos.",
                        column3: "May pagsusuri, ngunit hindi masyadong malalim.",
                        column4: "Pagsusuri na wala masyadong lalim.",
                        column5: "Walang pagsusuri."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Maayos ang pagkakaayos ng impormasyon; madaling sundan.",
                        column3: "Kaunting kalituhan sa organisasyon; kailangan ng kaunting pagbabago.",
                        column4: "Magulo; mahirap sundan.",
                        column5: "Walang malinaw na pagkakaayos."
                    },
                    {
                        column1: "Presentasyon",
                        column2: "Nakakaakit; mahusay na visual aids na tumutulong sa mensahe.",
                        column3: "Maganda ang visual aids, ngunit may kulang.",
                        column4: "Kakulangan sa visual aids; hindi nakakaakit.",
                        column5: "Walang visual aids o hindi angkop ang mga ito."
                    },
                ],
            },
            '“Tinig ng Manggagawa”': {
                objectives: " Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "1. Hatiin ang klase sa dalawang grupo. \n2. Bigyan ang bawat grupo ng 5 minuto upang talakayin ang kanilang natutunan mula sa kanilang pagsasaliksik. \n3. Pagkatapos ng 5 minuto, ipabahagi ng bawat grupo ang kanilang mga natuklasan. \n4. Mag-organisa ng isang debate kung saan ang bawat grupo ay tumatalakay sa mga benepisyo at hamon ng mga manggagawa sa bansa. \n5. I-assign ang mga papel ng mga pabor at hindi pabor sa isyu.",
                format: "I. Panimula \n• Grupo A (Pabor) - Pamagat ng isyu at layunin ng debate. \n• Grupo B (Hindi Pabor) - Pamagat ng isyu at layunin ng debate. \nII. Mga Benepisyo \n• Grupo A (Pabor) - Mga benepisyo para sa manggagawa (hal. mas mataas na sahod, seguridad sa trabaho, benepisyo sa kalusugan, atbp.) \n• Grupo B (Hindi Pabor) - Mga epekto ng mga benepisyo sa ekonomiya at lipunan. \nIII. Mga Hamon \n• Grupo A (Pabor) - Mga benepisyo sa bansa (hal. ekonomiya, pag-unlad ng mga industriya) \n• Grupo B (Hindi Pabor) - Mga epekto sa sektor ng negosyo, mga resyusyon na hindi natutugunan. \nKonklusyon \n• Grupo A (Pabor) - Pagpapatibay ng argumento at panghuling pahayag. \n• Grupo B (Hindi Pabor) - Pagpapatibay ng argumento at panghuling pahayag.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan",
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Napakahusay na paglahad; nakakaengganyo at detalyado ang argumento.",
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Mahusay na paglahad; magandang argumento ngunit may kulang sa detalye."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Katamtamang paglahad; hindi masyadong nakakaengganyo at may kakulangan."
                    },
                    {
                        column1: "Kailangan ng Pagsasanay",
                        column2: "Kakulangan sa paglahad; mahirap intidihin ang argumento."
                    },
                ],
            },
            '“Isang Pagtingin sa mga Batas sa Paggawa”': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "1. Sa loob ng 10 minuto, magsagawa ng isang pananaliksik tungkol sa mga umiiral na batas sa paggawa sa bansa. \n2. I-compile ang mga nakalap na impormasyon sa isang ulat. \n3. Ipresenta ang ulat sa klase.",
                format: "Pamagat ng Ulat \nI. Panimula \nII. Mga Umiiral na Batas sa Paggawa \nBatas 1: [Pangalan ng Batas] (Magdagdag pa ng mga batas ayon sa pangangailangan) \nIII.  Pagsusuri at Paghahambing \nIV. Konklusyon \nV. Mga Sanggunian",
                tableData: [
                    {
                        column1: "Krayterya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Kumpleto at detalyado ang impormasyon; nakatuon sa mga batas sa paggawa.",
                        column3: "Magandang impormasyon, ngunit may kulang sa detalye. ",
                        column4: "Nakakabit, ngunit may kakulangan sa impormasyon.",
                        column5: "Hindi malinaw; mahirap intidihin ang mensahe."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Malalim at kritikal na pagsusuri ng mga batas.",
                        column3: "May pagsusuri, ngunit hindi masyadong malalim.",
                        column4: "Pagsusuri na wala masyadong lalim.",
                        column5: "Walang pagsusuri."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Maayos ang pagkakaayos ng impormasyon; madaling sundan.",
                        column3: "Kaunting kalituhan sa organisasyon; kailangan ng kaunting pagbabago.",
                        column4: "Magulo; mahirap sundan.",
                        column5: "Walang malinaw na pagkakaayos."
                    },
                    {
                        column1: "Presentasyon",
                        column2: "Nakakaakit; mahusay na visual aids na tumutulong sa mensahe.",
                        column3: "Maganda ang visual aids, ngunit may kulang.",
                        column4: "Kakulangan sa visual aids; hindi nakakaakit.",
                        column5: "Walang visual aids o hindi angkop ang mga ito."
                    },
                ],
            },
            '“Pagsasanay sa Manggagawa”': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "1. Lumikha ng isang simpleng workshop o seminar. \n.2 Tiyakin na tutulong ito sa mga estudyante na maunawaan ang mga pangunahing kasanayan na kailangan sa trabaho. \n3. Itakda ang haba ng workshop o seminar sa 45 minuto.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Pamantayan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Napakahusay na pagsasanay; nakakaengganyo at detalyado ang nilalaman."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Mahusay na pagsasanay; magandang nilalaman ngunit may kulang sa detalye."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Katamtamang pagsasanay; hindi masyadong nakakaengganyo at may kakulangan."
                    },
                    {
                        column1: "Kailangan ng Pagsasanay",
                        column2: "Kakulangan sa pagsasanay; mahirap intidihin ang mensahe."
                    },
                ],
            },
            '"Ipahayag Mo, Pananaw Mo!"': {
                objectives: "Naipaliliwanag ng mga mag-aaral ang pagtugon sa mga isyu sa paggawa sa bansa sa pamamagitan ng iba't ibang gawain.",
                instructions: "1. Mag-organisa ng forum. \n2. Pumili ng mga estudyante bilang panelist. \n3. Hayaang magbigay ang mga panelist ng kanilang pananaw sa mga isyu sa paggawa. \n4. Tiyakin na magtatagal ang forum ng 35 minuto.",
                tableData: [
                    {
                        column1: "Krayterya",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan ng Pagsasanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Napakahusay na presentasyon ng mga pananaw. Naisalaysay ang kabuuan ng nilalaman na angkop sa pinag-uusapang paksa.",
                        column3: "Maganda ang presentasyon ng mga pananaw. Ngunit hindi lahat ay natalakay.",
                        column4: "May kakulangan sa presentasyon ng mga pananaw. Hindi gaanong nabigyang diin ang punto ng nilalaman.",
                        column5: "Walang naibigay ang mga pananaw ukol sa nilalaman ng paksa."
                    },
                    {
                        column1: "Pakikipag-ugnayan",
                        column2: "Napakahusay na pakikipag-ugnayan sa mga tagapakinig. Napapanatili ang eye contact at lubos na nakakarelate ang mga tagapakinig.",
                        column3: "Maayos ang pakikipag-ugnayan sa mga tagapakinig. Hindi gaanong naipapakita ang kumpyansa sa sarili.",
                        column4: "Katamtamang pakikipag-ugnayan sa mga tagapakinig. Hindi gaanong nagkakaroon ng koneksyon sa madla.",
                        column5: "Mahinang pakikipag-ugnayan. Hindi naipapamalas ang kumpyansa sa sarili at koneksyon sa madla."
                    },
                    {
                        column1: "Kahusayan sa Pagganap",
                        column2: "Napakahusay na pagsagot sa mga katanungan. Nabibigyang diin ang bawat punto at nakapagbibigay ng detalyadong sagot.",
                        column3: "Mahusay ang pagsagot sa katanungan ngunit hindi lahat ng punto ay nasagot.",
                        column4: "Katamtamang nasagot ang mga katanungan ngunit hindi malinaw na naipahayag ang punto.",
                        column5: "Hindi umaayon ang sagot sa mga katanungan."
                    },
                    {
                        column1: "Oras",
                        column2: "Nasunod ang oras ng talakayan at eksaktong nasasagot ang bawat katanungan.",
                        column3: "Kadalasang nasunod ang oras ngunit sumobra ng kaunti sa oras.",
                        column4: "Madalas na hindi nasunod ang oras.",
                        column5: "Hindi nasunod ang oras ng talakayan."
                    },
                ],
            },
        },
    },
    { //ARALIN 555555555555
        title: "Aralin 5: Salik at mga Dahilan ng Migrasyon",
        activities: {
            "Video Documentary": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon.",
                instructions: "Gumawa ng isang maikling dokumentaryo na nagkukuwento ng karanasan ng mga migrante. Maaaring mag-interview ng mga tunay na migrante o gumamit ng mga archival footage at mga larawan.",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan pang magsanay (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malinaw at detalyado ang mga impormasyon tungkol sa mga salik at dahilan ng migrasyon. May iba't ibang uri ng migrasyon na naipakita at may mga halimbawa.",
                        column3: "Malinaw ang mga impormasyon, ngunit may ilang bahagi na maaaring mas mapalalim pa.",
                        column4: "Nailahad ang mga pangunahing salik at dahilan ng migrasyon, ngunit hindi masyadong malinaw o detalyado ang mga paliwanag.",
                        column5: "Hindi malinaw ang mga impormasyon tungkol sa mga salik at dahilan ng migrasyon."
                    },
                    {
                        column1: "Organisasyon",
                        column2: "Maayos ang pagkakasunod-sunod ng mga impormasyon sa video. Malinaw ang pagkakahati ng mga ideya. Madaling maunawaan ang daloy ng mga ideya.",
                        column3: "May ilang bahagi na hindi organisado. Hindi masyadong malinaw ang pagkakahati ng mga impormasyon. May ilang bahagi na mahirap maunawaan.",
                        column4: "Hindi maayos ang organisasyon ng video. Hindi malinaw ang pagkakahati ng mga impormasyon. Mahirap maunawaan ang daloy ng mga ideya.",
                        column5: "Walang organisasyon ang video. Hindi malinaw ang pagkakahati ng mga impormasyon. Mahirap maunawaan ang daloy ng mga ideya."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Nakakaengganyo l ang video. May sariling pananaw at pag-iisip ang mga mag-aaral. Malinaw at kaakit-akit ang mga visual.",
                        column3: "May ilang bahagi na nakakaengganyo.May ilang mga pananaw at pag-iisip ang mga mag-aaral. Malinaw at kaakit-akit ang ilang mga visual.",
                        column4: "Hindi masyadong nakakaengganyo ang video. May ilang mga pananaw at pag-iisip ang mga mag-aaral. Hindi masyadong malinaw o kaakit-akit ang mga visual.",
                        column5: "Hindi nakakaengganyo ang video. Walang sariling pananaw at pag-iisip ang mga mag-aaral. Hindi malinaw o kaakit-akit ang mga visual."
                    },
                    {
                        column1: "Teknikal na Kasanayan",
                        column2: "Malinis, maayos, at kaaya-aya ang presentasyon ng video. Malinaw at madaling mabasa ang mga teksto. Ang mga kulay at disenyo ay tumutugma sa paksa.",
                        column3: "May ilang bahagi na hindi malinis at maayos. May ilang mga teksto na mahirap mabasa. Ang mga kulay at disenyo ay hindi tumutugma sa paksa.",
                        column4: "Hindi malinis at maayos ang presentasyon ng video. Mahirap mabasa ang mga teksto. Ang mga kulay at disenyo ay hindi tumutugma sa paksa.",
                        column5: "Hindi malinis at maayos ang presentasyon ng video. Mahirap mabasa ang mga teksto. Ang mga kulay at disenyo ay hindi tumutugma sa paksa."
                    },
                ],
            },
            "Painting": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon.",
                instructions: "Magpinta sa canvas na may sukat na 240 cm x 360 cm (94.49 in x 141.73 in) na nagpapakita ng mga salik at dahilan ng migrasyon. Dapat isama ang mga larawan, mga teksto, at mga simbolo na nagpapaliwanag sa mga konsepto.",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan pang magsanay (1)"
                    },
                    {
                        column1: "Nilalalaman",
                        column2: "Malinaw at detalyado ang mga impormasyon tungkol sa mga salik at dahilan ng migrasyon. May iba't ibang uri ng migrasyon na naipakita at may mga halimbawa.",
                        column3: "Malinaw ang mga impormasyon, ngunit may ilang bahagi na maaaring mas mapalalim pa.",
                        column4: "Nailahad ang mga pangunahing salik at dahilan ng migrasyon, ngunit hindi masyadong malinaw o detalyado ang mga paliwanag.",
                        column5: "Hindi malinaw ang mga impormasyon tungkol sa mga salik at dahilan ng migrasyon."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Malikhain at orihinal ang proyekto. May sariling pananaw at pag-iisip ang mag-aaral.",
                        column3: "May ilang bahagi na malikhain, ngunit may ilang aspeto na maaaring mas mapabuti pa.",
                        column4: "Hindi gaanong malikhain ang proyekto. May ilang mga pagkakamali na nakakaapekto sa pangkalahatang pagkakaunawa sa mga impormasyon.",
                        column5: "Hindi malikhain ang proyekto. Maraming pagkakamali na nakakaapekto sa pangkalahatang pagkakaunawa sa mga impormasyon."
                    },
                    {
                        column1: "Presentasyon",
                        column2: "Maganda at malinis ang presentasyon ng proyekto. May malinaw na pagkukuwento at angkop na mga visual.",
                        column3: "May mga pagkakamali sa presentasyon, ngunit hindi ito nakakaapekto sa pangkalahatang pagkakaunawa sa mga impormasyon.",
                        column4: "Hindi gaanong maganda ang presentasyon ng proyekto. May ilang mga pagkakamali na nakakaapekto sa pangkalahatang pagkakaunawa sa mga impormasyon.",
                        column5: "Hindi maganda ang presentasyon ng proyekto. Maraming pagkakamali na nakakaapekto sa pangkalahatang pagkakaunawa sa mga impormasyon."
                    },
                    {
                        column1: "Pag-unawa",
                        column2: "Lubos na naipakita ang pag-unawa sa mga salik at dahilan ng migrasyon. Malinaw, detalyado, at malikhain ang presentasyon ng mga impormasyon.",
                        column3: "Naipakita ang pag-unawa sa mga salik at dahilan ng migrasyon, ngunit may ilang bahagi na maaaring mas mapalalim pa.",
                        column4: "May ilang mga pagkakamali sa pag-unawa sa paksa at hindi gaanong malinaw ang paglalahad ng mga konsepto.",
                        column5: "Hindi naipakita ang pag-unawa sa paksa at mali ang mga ideya na ipinakita."
                    },
                ],
            },
            "Role Play": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon.",
                instructions: "Gumawa ng isang maikling dula na nagpapakita ng karanasan ng isang pamilyang lumilipat sa ibang bansa dahil sa kahirapan. Maaaring magpokus sa mga hamon at oportunidad na kinakaharap ng pamilya sa kanilang bagong tahanan. ",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Pag-unawa sa Tema",
                        column2: "Malinaw na naipakita ang mga hamon at oportunidad na kinakaharap ng pamilya sa kanilang bagong tahanan. Ang dula ay nagbibigay ng malalim na pag-unawa sa mga epekto ng migrasyon sa pamilya.",
                        column3: "Naipakita ang mga hamon at oportunidad, ngunit hindi gaanong malalim ang pag-unawa. Ang dula ay nagbibigay ng pangkalahatang pag-unawa sa mga epekto ng migrasyon.",
                        column4: "Hindi gaanong malinaw ang pagpapakita ng mga hamon at oportunidad. Ang dula ay hindi nagbibigay ng malinaw na pag-unawa sa mga epekto ng migrasyon.",
                        column5: "Hindi naipakita ang mga hamon at oportunidad. Ang dula ay hindi nagbibigay ng pag-unawa sa mga epekto ng migrasyon."
                    },
                    {
                        column1: "Pagganap",
                        column2: "Ang mga aktor ay nagpakita ng natural at makatotohanang pagganap. Ang kanilang mga kilos, ekspresyon, at boses ay tumutugma sa kanilang mga karakter.",
                        column3: "Ang mga aktor ay nagpakita ng mahusay na pagganap. Ang kanilang mga kilos, ekspresyon, at boses ay karaniwang tumutugma sa kanilang mga karakter.",
                        column4: "Ang mga aktor ay nagpakita ng katamtamang pagganap. Ang kanilang mga kilos, ekspresyon, at boses ay hindi palaging tumutugma sa kanilang mga karakter.",
                        column5: "Ang mga aktor ay hindi nagpakita ng mahusay na pagganap. Ang kanilang mga kilos, ekspresyon, at boses ay hindi tumutugma sa kanilang mga karakter."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Ang dula ay orihinal at malikhain. Ang mga dayalogo ay kawili-wili at nakaka-engganyo. Ang mga eksena ay mahusay na pinlano at nagbibigay ng malakas na visual na epekto.",
                        column3: "Ang dula ay malikhain. Ang mga dayalogo ay nakaka-engganyo. Ang mga eksena ay mahusay na pinlano.",
                        column4: "Ang dula ay hindi gaanong malikhain. Ang mga dayalogo ay hindi gaanong nakaka-engganyo. Ang mga eksena ay hindi gaanong mahusay na pinlano.",
                        column5: "Ang dula ay hindi malikhain. Ang mga dayalogo ay hindi nakaka-engganyo. Ang mga eksena ay hindi mahusay na pinlano."
                    },
                    {
                        column1: "Pagkakaisa",
                        column2: "Ang dula ay maayos na pinagsama-sama. Ang mga eksena ay magkakaugnay at nagbibigay ng malinaw na kwento. Ang dula ay may malinaw na simula, gitna, at wakas.",
                        column3: "Ang dula ay karaniwang maayos na pinagsama-sama. Ang mga eksena ay karaniwang magkakaugnay at nagbibigay ng malinaw na kwento.",
                        column4: "Ang dula ay hindi gaanong maayos na pinagsama-sama. Ang mga eksena ay hindi gaanong magkakaugnay at hindi nagbibigay ng malinaw na kwento.",
                        column5: "Ang dula ay hindi maayos na pinagsama-sama. Ang mga eksena ay hindi magkakaugnay at hindi nagbibigay ng malinaw na kwento."
                    },
                ],
            },
            "Laro": {
                objectives: "Nasusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon.",
                instructions: "Ang klase ay mahahati sa 3 grupo, ang bawat grupo ay magdidisenyo ng isang board game o card game na nagpapakita ng mga salik at dahilan ng migrasyon. Ang mga manlalaro ay dapat gumawa ng mga desisyon batay sa mga salik na ito upang makamit ang kanilang mga layunin sa laro.",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Ang laro ay nagpapakita ng malalim na pag-unawa sa mga salik at dahilan ng migrasyon. Ang mga mekanika ng laro ay tumpak na nagpapakita ng mga konsepto ng migrasyon. Ang mga card o tile ay may malinaw na impormasyon tungkol sa mga salik at dahilan ng migrasyon.",
                        column3: "Ang laro ay nagpapakita ng pag-unawa sa mga salik at dahilan ng migrasyon. Ang mga mekanika ng laro ay tumpak na nagpapakita ng mga konsepto ng migrasyon. Ang mga card o tile ay may impormasyon tungkol sa mga salik at dahilan ng migrasyon.",
                        column4: "Ang laro ay nagpapakita ng limitadong pag-unawa sa mga salik at dahilan ng migrasyon. Ang mga mekanika ng laro ay hindi tumpak na nagpapakita ng mga konsepto ng migrasyon. Ang mga card o tile ay may kaunting impormasyon tungkol sa mga salik at dahilan ng migrasyon.",
                        column5: "Ang laro ay hindi nagpapakita ng pag-unawa sa mga salik at dahilan ng migrasyon. Ang mga mekanika ng laro ay hindi tumpak na nagpapakita ng mga konsepto ng migrasyon. Ang mga card o tile ay walang impormasyon tungkol sa mga salik at dahilan ng migrasyon."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Ang laro ay orihinal, malikhain, at nakakaakit. Ang mga mekanika ng laro ay natatangi at nakaka-engganyo. Ang disenyo ng laro ay biswal na nakaka-engganyo at nagpapakita ng pagkamalikhain.",
                        column3: "Ang laro ay malikhain. Ang mga mekanika ng laro ay nakaka-engganyo. Ang disenyo ng laro ay biswal na nakaka-engganyo.",
                        column4: "Ang laro ay hindi gaanong malikhain. Ang mga mekanika ng laro ay hindi gaanong nakaka-engganyo. Ang disenyo ng laro ay hindi gaanong biswal na nakaka-engganyo.",
                        column5: "Ang laro ay hindi malikhain. Ang mga mekanika ng laro ay hindi nakaka-engganyo. Ang disenyo ng laro ay hindi biswal na nakaka-engganyo."
                    },
                    {
                        column1: "Paglalaro",
                        column2: "Ang laro ay madaling matutunan at laruin. Ang mga panuntunan ay malinaw at madaling maunawaan. Ang laro ay nagbibigay ng kasiyahan at nakaka-engganyo sa mga manlalaro.",
                        column3: "Ang laro ay madaling matutunan at laruin. Ang mga panuntunan ay malinaw. Ang laro ay nagbibigay ng kasiyahan sa mga manlalaro.",
                        column4: "Ang laro ay medyo mahirap matutunan at laruin. Ang mga panuntunan ay hindi gaanong malinaw. Ang laro ay hindi gaanong nagbibigay ng kasiyahan sa mga manlalaro.",
                        column5: "Ang laro ay mahirap matutunan at laruin. Ang mga panuntunan ay hindi malinaw. Ang laro ay hindi nagbibigay ng kasiyahan sa mga manlalaro."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Ang laro ay nagpapakita ng malalim na pagsusuri sa mga salik at dahilan ng migrasyon. Ang mga desisyon na ginagawa ng mga manlalaro ay nagpapakita ng pag-unawa sa mga konsepto ng migrasyon. Ang laro ay nagbibigay ng pagkakataon para sa mga manlalaro na mag-isip nang malalim tungkol sa mga isyu ng migrasyon.",
                        column3: "Ang laro ay nagpapakita ng pagsusuri sa mga salik at dahilan ng migrasyon. Ang mga desisyon na ginagawa ng mga manlalaro ay nagpapakita ng pag-unawa sa mga konsepto ng migrasyon.",
                        column4: "Ang laro ay nagpapakita ng limitadong pagsusuri sa mga salik at dahilan ng migrasyon. Ang mga desisyon na ginagawa ng mga manlalaro ay hindi nagpapakita ng malalim na pag-unawa sa mga konsepto ng migrasyon.",
                        column5: "Ang laro ay hindi nagpapakita ng pagsusuri sa mga salik at dahilan ng migrasyon. Ang mga desisyon na ginagawa ng mga manlalaro ay hindi nagpapakita ng pag-unawa sa mga konsepto ng migrasyon."
                    },
                ],
            },
            "Musika": {
                objectives: "Nsusuri ang mga salik at dahilan ng migrasyon dulot ng globalisasyon.",
                instructions: "Ang klase ay mahahati sa 2 grupo, ang bawat grupo ay gagawa ng isang kanta, rap, o musical piece na nagkukuwento tungkol sa karanasan ng isang taong lumilipat sa ibang bansa dahil sa migrasyon. Maaaring ituon ang pansin sa kanilang mga emosyon, hamon, at mga oportunidad.",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Ang kanta/rap/musical piece ay nagpapakita ng malalim na pag-unawa sa karanasan ng isang taong lumilipat sa ibang bansa dahil sa migrasyon. Ang mga lyrics ay tumpak na naglalarawan ng mga emosyon, hamon, at mga oportunidad na kinakaharap ng migranteng indibidwal.",
                        column3: "Ang kanta/rap/musical piece ay nagpapakita ng pag-unawa sa karanasan ng isang taong lumilipat sa ibang bansa. Ang mga lyrics ay naglalarawan ng mga emosyon, hamon, at mga oportunidad na kinakaharap ng migranteng indibidwal.",
                        column4: "Ang kanta/rap/musical piece ay nagpapakita ng limitadong pag-unawa sa karanasan ng isang taong lumilipat sa ibang bansa. Ang mga lyrics ay hindi gaanong tumpak na naglalarawan ng mga emosyon, hamon, at mga oportunidad na kinakaharap ng migranteng indibidwal.",
                        column5: "Ang kanta/rap/musical piece ay hindi nagpapakita ng pag-unawa sa karanasan ng isang taong lumilipat sa ibang bansa. Ang mga lyrics ay hindi naglalarawan ng mga emosyon, hamon, at mga oportunidad na kinakaharap ng migranteng indibidwal."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Ang kanta/rap/musical piece ay orihinal, malikhain, at nakakaakit. Ang mga lyrics ay kawili-wili at nakaka-engganyo. Ang melodiya, ritmo, at pag-aayos ay nagpapakita ng pagkamalikhain at nagbibigay ng malakas na epekto sa mga nakikinig.",
                        column3: "Ang kanta/rap/musical piece ay malikhain. Ang mga lyrics ay nakaka-engganyo. Ang melodiya, ritmo, at pag-aayos ay nagpapakita ng pagkamalikhain.",
                        column4: "Ang kanta/rap/musical piece ay hindi gaanong malikhain. Ang mga lyrics ay hindi gaanong nakaka-engganyo. Ang melodiya, ritmo, at pag-aayos ay hindi gaanong nagpapakita ng pagkamalikhain.",
                        column5: "Ang kanta/rap/musical piece ay hindi malikhain. Ang mga lyrics ay hindi nakaka-engganyo. Ang melodiya, ritmo, at pag-aayos ay hindi nagpapakita ng pagkamalikhain."
                    },
                    {
                        column1: "Pagganap",
                        column2: "Ang pagganap ay nagpapakita ng malalim na pag-unawa sa damdamin at mensahe ng kanta/rap/musical piece. Ang boses, tono, at ekspresyon ay tumutugma sa mga lyrics at nagbibigay ng malakas na epekto sa mga nakikinig.",
                        column3: "Ang pagganap ay nagpapakita ng pag-unawa sa damdamin at mensahe ng kanta/rap/musical piece. Ang boses, tono, at ekspresyon ay tumutugma sa mga lyrics.",
                        column4: "Ang pagganap ay hindi gaanong nagpapakita ng pag-unawa sa damdamin at mensahe ng kanta/rap/musical piece. Ang boses, tono, at ekspresyon ay hindi gaanong tumutugma sa mga lyrics.",
                        column5: "Ang pagganap ay hindi nagpapakita ng pag-unawa sa damdamin at mensahe ng kanta/rap/musical piece. Ang boses, tono, at ekspresyon ay hindi tumutugma sa mga lyrics."
                    },
                    {
                        column1: "Pagsusuri",
                        column2: "Ang kanta/rap/musical piece ay nagpapakita ng malalim na pagsusuri sa mga isyu ng migrasyon. Ang mga lyrics ay nagbibigay ng pagkakataon para sa mga nakikinig na mag-isip nang malalim tungkol sa mga epekto ng migrasyon sa mga indibidwal at sa lipunan.",
                        column3: "Ang kanta/rap/musical piece ay nagpapakita ng pagsusuri sa mga isyu ng migrasyon. Ang mga lyrics ay nagbibigay ng pagkakataon para sa mga nakikinig na mag-isip tungkol sa mga epekto ng migrasyon.",
                        column4: "Ang kanta/rap/musical piece ay nagpapakita ng limitadong pagsusuri sa mga isyu ng migrasyon. Ang mga lyrics ay hindi gaanong nagbibigay ng pagkakataon para sa mga nakikinig na mag-isip tungkol sa mga epekto ng migrasyon.",
                        column5: "Ang kanta/rap/musical piece ay hindi nagpapakita ng pagsusuri sa mga isyu ng migrasyon. Ang mga lyrics ay hindi nagbibigay ng pagkakataon para sa mga nakikinig na mag-isip tungkol sa mga epekto ng migrasyon."
                    },
                ],
            },
        },
    },
    { //ARALIN 666666666666
        title: "Aralin 6: Epekto ng Migrasyon Dulot ng Globalisasyon",
        activities: {
            "Podcast Episode": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: "Ang klase ay hahatiin sa 3 grupo upang gumawa ng podcast episode. Pumili ng isang tema o paksa sa loob ng Aralin 6: Epekto ng Migrasyon dulot ng Globalisasyon. Halimbawa, maaari ninyong talakayin ang mga epekto ng migrasyon sa pamilya, ekonomiya, o kultura. Ibahagi ang inyong podcast episode sa pamamagitan ng mga social media platforms o website.",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Lubos na Nakamit (4)",
                        column3: "Nakamit (3)",
                        column4: "Bahagyang Nakamit (2)",
                        column5: "Hindi Nakamit (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Ang paksa ay lubos na malinaw at nauugnay sa Aralin 6. Ang mga ideya ay mahusay na organisado at may matibay na suporta.",
                        column3: "Ang paksa ay malinaw at nauugnay sa Aralin 6. Ang mga ideya ay organisado at may sapat na suporta.",
                        column4: "Ang paksa ay medyo malinaw at nauugnay sa Aralin 6. Ang mga ideya ay bahagyang organisado at may limitadong suporta.",
                        column5: "Ang paksa ay hindi malinaw at hindi nauugnay sa Aralin 6. Ang mga ideya ay hindi organisado at kulang sa suporta."
                    },
                    {
                        column1: "Paglalahad",
                        column2: "Ang paglalahad ay lubos na malinaw at madaling maintindihan. Ang mga boses ay napakaklaro at may napakagandang kalidad ng tunog.",
                        column3: "Ang paglalahad ay malinaw at madaling maintindihan. Ang mga boses ay malinaw at may mahusay na kalidad ng tunog.",
                        column4: "Ang paglalahad ay medyo malinaw at madaling maintindihan. Ang mga boses ay medyo malinaw at may katamtamang kalidad ng tunog.",
                        column5: "Ang paglalahad ay hindi malinaw at mahirap maintindihan. Ang mga boses ay hindi malinaw at may mahinang kalidad ng tunog."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Ang podcast ay may lubos na pagkamalikhain at napakakaengganyo.",
                        column3: "Ang podcast ay may mahusay na pagkamalikhain at nakakaengganyo.",
                        column4: "Ang podcast ay may bahagyang pagkamalikhain at medyo nakakaengganyo.",
                        column5: "Ang podcast ay kulang sa pagkamalikhain at hindi nakakaengganyo."
                    },
                    {
                        column1: "Pagkakaisa",
                        column2: "Ang mga miyembro ng grupo ay nagpakita ng lubos na pagtutulungan at napakapantay ang kontribusyon.",
                        column3: "Ang mga miyembro ng grupo ay nagpakita ng mahusay na pagtutulungan at pantay ang kontribusyon.",
                        column4: "Ang mga miyembro ng grupo ay nagpakita ng bahagyang pagtutulungan at hindi pantay ang kontribusyon.",
                        column5: "Ang mga miyembro ng grupo ay hindi nagpakita ng pagtutulungan at hindi pantay ang kontribusyon."
                    },
                ],
            },
            "Cultural Exchange Day": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: "Hahatiin ang klase sa 2 grupo at magkakaroon ng pagkakataon para sa bawat grupo na magpakilala ng kultura ng kanilang napiling bansa sa Asya. Ang mga mag-aaral ay magbabahagi ng mga kanta ng mga kanta, sayaw, pagkain, sining, at tradisyunal na laro mula sa bansang kanilang napili. ",
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Napakahusay (4)",
                        column3: "Mahusay (3)",
                        column4: "Katamtaman (2)",
                        column5: "Kailangan Pang Pagbutihan (1)"
                    },
                    {
                        column1: "Pagpapakilala ng Kultura",
                        column2: "Malinaw at detalyadong pagpapakilala ng kultura ng napiling bansa sa Asya. Nagpakita ng malawak na kaalaman sa mga tradisyon, kaugalian, at sining.",
                        column3: "Malinaw na pagpapakilala ng kultura ng napiling bansa. Nagpakita ng sapat na kaalaman sa mga tradisyon, kaugalian, at sining.",
                        column4: "May ilang bahagi ng pagpapakilala na hindi malinaw o kulang sa detalye. Nagpakita ng limitadong kaalaman sa mga tradisyon, kaugalian, at sining.",
                        column5: "Hindi malinaw at hindi kumpleto ang pagpapakilala ng kultura ng napiling bansa. Kulang sa kaalaman sa mga tradisyon, kaugalian, at sining."
                    },
                    {
                        column1: "Pagtatanghal",
                        column2: "Nakakaengganyo at mahusay na pagtatanghal ng mga kanta, sayaw, pagkain, sining, at tradisyunal na laro. Malinaw ang presentasyon at nakakaakit sa madla.",
                        column3: "Nakakaengganyo at maayos na pagtatanghal ng mga kanta, sayaw, pagkain, sining, at tradisyunal na laro. Malinaw ang presentasyon.",
                        column4: "May ilang bahagi ng pagtatanghal na hindi nakakaengganyo o hindi maayos. Hindi gaanong malinaw ang presentasyon.",
                        column5: "Hindi nakakaengganyo at hindi maayos ang pagtatanghal. Hindi malinaw ang presentasyon at hindi nakakaakit sa madla."
                    },
                    {
                        column1: "Pagtutulungan at Pakikipag-ugnayan",
                        column2: "Nagpakita ng mahusay na pagtutulungan at pakikipag-ugnayan sa mga kasamahan sa grupo. Aktibong nakilahok sa lahat ng aspeto ng presentasyon.",
                        column3: "Nagpakita ng pagtutulungan at pakikipag-ugnayan sa mga kasamahan sa grupo. Aktibong nakilahok sa karamihan ng mga aspeto ng presentasyon.",
                        column4: "May ilang pagkakataon na hindi nagpakita ng pagtutulungan o pakikipag-ugnayan sa mga kasamahan sa grupo. Hindi gaanong aktibong nakilahok sa presentasyon.",
                        column5: "Hindi nagpakita ng pagtutulungan at pakikipag-ugnayan sa mga kasamahan sa grupo. Hindi aktibong nakilahok sa presentasyon."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Napakahusay na pagkamalikhain sa pagtatanghal ng kultura. Natatangi at nakaka-engganyo ang mga presentasyon.",
                        column3: "May pagkamalikhain sa pagtatanghal ng kultura. May ilang natatanging ideya at presentasyon.",
                        column4: "May ilang pagkamalikhain sa pagtatanghal ng kultura.",
                        column5: "Walang pagkamalikhain sa pagtatanghal ng kultura."
                    },
                ],
            },
            "Magazine": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: "Gumawa ng magazine na nagpapakita ng epekto ng migrasyon dulot ng globalisasyon sa iba’t ibang kultura, at maglaman ng mga artikulo, larawan, at panayam na nagpapakita ng mga positibo at negatibong epekto ng migrasyon sa ating lipunan. Maging malikhain sa pagdidisenyo ng inyong magazine.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "4 - Napakahusay",
                        column2: "Ang magazine ay nagpapakita ng malalim na pag-unawa sa epekto ng migrasyon dulot ng globalisasyon sa iba't ibang kultura. Ang nilalaman ay nagtataglay ng mga artikulo, larawan, at panayam na nagpapakita ng mga positibo at negatibong epekto ng migrasyon sa ating lipunan. Ang disenyo ay malikhain at nakakaengganyo, at ang presentasyon ay maayos at organisado."
                    },
                    {
                        column1: "3 - Mahusay",
                        column2: "Ang magazine ay nagpapakita ng pag-unawa sa epekto ng migrasyon dulot ng globalisasyon, ngunit may ilang bahagi na maaaring mas mapalalim pa. Ang nilalaman ay nagtataglay ng mga artikulo, larawan, at panayam na nagpapakita ng mga positibo at negatibong epekto ng migrasyon, ngunit maaaring kulang sa detalye o lalim. Ang disenyo ay malikhain at nakakaengganyo, ngunit may ilang bahagi na maaaring mas mapahusay pa."
                    },
                    {
                        column1: "2 - Katamtaman",
                        column2: "Ang magazine ay nagpapakita ng pangunahing pag-unawa sa epekto ng migrasyon dulot ng globalisasyon, ngunit kulang sa lalim at detalye. Ang nilalaman ay nagtataglay ng mga artikulo, larawan, at panayam, ngunit maaaring hindi sapat ang mga ito upang maipakita ang mga positibo at negatibong epekto ng migrasyon. Ang disenyo ay simple at hindi gaanong nakakaengganyo."
                    },
                    {
                        column1: "1 - Kailangan pang Paunlarin",
                        column2: "Ang magazine ay hindi nagpapakita ng sapat na pag-unawa sa epekto ng migrasyon dulot ng globalisasyon. Ang nilalaman ay hindi organisado at kulang sa detalye. Ang disenyo ay hindi malikhain at hindi nakakaengganyo."
                    },
                ],
            },
            "Infomercial": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: 'Ang klase ay mahahati sa 2 grupo at ang bawat grupo ay pipili ng isang tema na nasa ibaba para sa gagawing informercial. Maging malikhain at sensitibo sa mga isyu ng migrasyon at iwasan ang paggamit ng mga diskriminatoryong wika \n1. “Globalisasyon: Bagong Daigdig, Bagong Pag-asa?" - Tumatalakay sa mga oportunidad na dulot ng globalisasyon, tulad ng pag-unlad ng ekonomiya at pagpapalitan ng kultura. \n2. "Migrasyon: Paghahanap ng Bagong Buhay" - Nagpapakita ng mga karanasan ng mga migranteng manggagawa, ang mga hamon na kinakaharap nila, at ang mga positibong epekto ng kanilang pagsisikap. \n3. "Globalisasyon: Hamon sa Kultura" - Tumatalakay sa mga negatibong epekto ng globalisasyon sa kultura, tulad ng pagkawala ng tradisyon at ang paglaganap ng Westernization. \n4. "Pagkakaisa sa Gitna ng Pagkakaiba" - Nagpapakita ng mga paraan kung paano nagkakaisa ang mga tao mula sa iba’t ibang kultura sa kabila ng mga pagkakaiba.',
                tableData: [
                    {
                        column1: "Pamantayan",
                        column2: "Lubos na Nakamit (4)",
                        column3: "Nakamit (3)",
                        column4: "Bahagyang Nakamit (2)",
                        column5: "Hindi Nakamit (1)"
                    },
                    {
                        column1: "Nilalaman",
                        column2: "Malinaw at komprehensibo ang presentasyon ng tema. Naglalaman ng tumpak at makatotohanang impormasyon. May malalim na pag-unawa sa isyu ng migrasyon at globalisasyon.",
                        column3: "Malinaw ang presentasyon ng tema. May ilang impormasyon na hindi tumpak o hindi maayos ang paglalahad. May pangunahing pag-unawa sa isyu ng migrasyon at globalisasyon.",
                        column4: "Hindi gaanong malinaw ang presentasyon ng tema. Maraming impormasyon na hindi tumpak o hindi maayos ang paglalahad. Limitado ang pag-unawa sa isyu ng migrasyon at globalisasyon.",
                        column5: "Hindi malinaw ang presentasyon ng tema. Walang maayos na paglalahad ng impormasyon. Walang pag-unawa sa isyu ng migrasyon at globalisasyon."
                    },
                    {
                        column1: "Pagkamalikhain",
                        column2: "Napakasining at orihinal ang presentasyon. May malawak na paggamit ng mga malikhaing elemento, tulad ng visual effects, musika, at mga nakakatawang eksena.",
                        column3: "May ilang malikhaing elemento na ginamit sa presentasyon. May pagkakaiba-iba sa paggamit ng mga visual effects, musika, at mga eksena.",
                        column4: "Limitado ang paggamit ng mga malikhaing elemento. Ang mga visual effects, musika, at mga eksena ay hindi gaanong nakakaengganyo.",
                        column5: "Walang malikhaing elemento na ginamit sa presentasyon. Ang mga visual effects, musika, at mga eksena ay hindi nakakaengganyo."
                    },
                    {
                        column1: "Pagtatanghal",
                        column2: "Napakahusay ang pagtatanghal. Malinaw at malakas ang boses. Ang mga aktor ay nagpakita ng mahusay na pag-arte. Ang mga transition sa pagitan ng mga eksena ay makinis at maayos.",
                        column3: "Mahusay ang pagtatanghal. Malinaw ang boses. Ang mga aktor ay nagpakita ng mahusay na pag-arte. May ilang mga transition sa pagitan ng mga eksena na hindi gaanong maayos.",
                        column4: "Hindi gaanong mahusay ang pagtatanghal. Hindi gaanong malinaw ang boses. Ang mga aktor ay hindi gaanong nagpakita ng mahusay na pag-arte. Maraming mga transition sa pagitan ng mga eksena na hindi maayos.",
                        column5: "Hindi mahusay ang pagtatanghal. Hindi malinaw ang boses. Ang mga aktor ay hindi nagpakita ng mahusay na pag-arte. Ang mga transition sa pagitan ng mga eksena ay hindi maayos."
                    },
                    {
                        column1: "Pag-iwas sa diskriminasyon",
                        column2: "Walang anumang diskriminatoryong wika o imahe na ginamit sa presentasyon. Ang presentasyon ay nagpapakita ng paggalang at pagtanggap sa lahat ng kultura.",
                        column3: "May ilang mga bahagi ng presentasyon na maaaring maituring na diskriminatoryo. Ang presentasyon ay hindi gaanong nagpapakita ng paggalang at pagtanggap sa lahat ng kultura.",
                        column4: "Maraming mga bahagi ng presentasyon na maaaring maituring na diskriminatoryo. Ang presentasyon ay hindi nagpapakita ng paggalang at pagtanggap sa lahat ng kultura.",
                        column5: "Ang presentasyon ay naglalaman ng maraming diskriminatoryong wika at imahe. Ang presentasyon ay hindi nagpapakita ng paggalang at pagtanggap sa lahat ng kultura."
                    },
                ],
            },
            "Photo Essay": {
                objectives: "Nasusuri ang epekto ng migrasyon dulot ng globalisasyon.",
                instructions: "Gumawa ng isang photo essay na nagpapakita ng epekto ng migrasyon dulot ng globalisasyon. Sa pamamagitan ng mga larawan at maikling caption, ipakita ang iba't ibang aspeto ng migrasyon, tulad ng pag-alis ng mga mahal sa buhay, paghahanap ng bagong buhay sa ibang bansa, at ang mga hamon at oportunidad na dala ng globalisasyon. Siguraduhing maipakita ang mga damdamin at karanasan ng mga taong apektado ng migrasyon.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "4 - Napakahusay",
                        column2: "Ang photo essay ay nagpapakita ng malalim na pag-unawa sa epekto ng migrasyon dulot ng globalisasyon. Ang mga larawan ay mahusay na napili at nakapagbibigay ng malinaw na mensahe. Ang mga caption ay maikli ngunit epektibo, at nagbibigay ng karagdagang konteksto sa mga larawan. Ang kabuuan ng photo essay ay nakakaengganyo at nagbibigay ng malalim na pag-iisip sa mga mambabasa."
                    },
                    {
                        column1: "3 - Mahusay",
                        column2: "Ang photo essay ay nagpapakita ng pag-unawa sa epekto ng migrasyon dulot ng globalisasyon. Ang mga larawan ay mahusay na napili, ngunit maaaring may ilang mga larawan na hindi gaanong epektibo. Ang mga caption ay maikli at nagbibigay ng konteksto, ngunit maaaring may ilang mga caption na hindi gaanong malinaw. Ang kabuuan ng photo essay ay nakakaengganyo, ngunit maaaring may ilang bahagi na hindi gaanong malalim."
                    },
                    {
                        column1: "2 - Katamtaman",
                        column2: "Ang photo essay ay nagpapakita ng pangunahing pag-unawa sa epekto ng migrasyon dulot ng globalisasyon. Ang mga larawan ay hindi gaanong epektibo at hindi gaanong malinaw ang mensahe. Ang mga caption ay hindi gaanong maikli at hindi gaanong epektibo, at hindi gaanong nagbibigay ng karagdagang konteksto sa mga larawan. Ang kabuuan ng photo essay ay hindi gaanong nakakaengganyo at hindi gaanong nagbibigay ng malalim na pag-iisip sa mga mambabasa."
                    },
                    {
                        column1: "1 - Kailangan pang Paunlarin",
                        column2: "Ang photo essay ay hindi nagpapakita ng pag-unawa sa epekto ng migrasyon dulot ng globalisasyon. Ang mga larawan ay hindi gaanong epektibo at hindi malinaw ang mensahe. Ang mga caption ay hindi gaanong maikli at hindi nagbibigay ng karagdagang konteksto sa mga larawan. Ang kabuuan ng photo essay ay hindi nakakaengganyo at hindi nagbibigay ng malalim na pag-iisip sa mga mambabasa."
                    },
                ],
            },
        },
    },
    { //ARALIN 77777777777
        title: "Aralin 7: Epekto ng Globalisasyon sa Aspektong Pang-Ekonomiya, Pangteknolohikal, Panlipunan at Sosyo-Kultural",
        activities: {
            "Info-mercial": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "Lumikha ng isang info-mercial na mayroon haba na 2-3 minuto na tumatalakay sa epekto ng globalisasyon sa mga aspektong pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural. Gumamit ng malikhaing pamamaraan upang makahikayat ng interes mula sa mga manonood.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Lubos na malinaw at makabuluhan ang mensahe ng info-mercial. Nailahad nang maayos at malikhain ang mga aspeto ng globalisasyon sa pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural. Nakapukaw ng interes ng mga manonood at naipaliwanag nang detalyado ang epekto ng globalisasyon."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Malinaw ang mensahe ng info-mercial at naipakita ang mga aspeto ng globalisasyon, ngunit may ilang bahagi na maaaring mas mapalalim pa. Gumamit ng sapat na malikhaing paraan upang maiparating ang mensahe, ngunit may ilang aspeto na maaaring mapaganda pa."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Hindi gaanong malinaw o kumpleto ang pagpapaliwanag ng mga aspeto ng globalisasyon. May mga bahagi ng info-mercial na kulang sa detalye o hindi sapat ang kreatibidad upang makatawag ng pansin. Ang mensahe ay naipahayag ngunit kulang sa lalim at kahusayan."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Hindi malinaw ang mensahe ng info-mercial at hindi sapat ang pagpapaliwanag ng mga aspeto ng globalisasyon. Hindi nagamit ang malikhaing pamamaraan upang maipakita ang epekto ng globalisasyon, at hindi nito nakuha ang atensyon ng mga manonood. Mahirap intindihin ang mensahe dahil sa kakulangan ng detalye at organisasyon."
                    },
                ],
            },
            "Role-play": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "1. Mahahati ang buong klase sa apat na grupo, at bawat grupo ay gagawa ng isang role-play na nagpapakita ng epekto ng globalisasyon sa apat na aspektong tinalakay. \n2. Ang mga grupo ay nahahati ayon sa mga sumusunod na aspeto: \n• Pangkat 1: Pang-ekonomiya \n• Pangkat 2: Pangteknolohikal \n• Pangkat 3: Panlipunan \n• Pangkat 4: Sosyo-kultural \n3. Ang bawat grupo ay magkakaroon lamang ng 5-7 minuto upang ipresenta ang kanilang role-play.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Lubos na makabuluhan at malinaw ang presentasyon ng aspeto ng globalisasyon. Maayos ang storyline, kapansin-pansin ang pagganap ng bawat miyembro, at malikhaing nagamit ang mga props at costume. Ang mga positibo at negatibong epekto ay naipakita nang mahusay, at lubos na nakuha ang atensyon ng mga manonood."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Malinaw ang mensahe at nailahad ang aspeto ng globalisasyon, ngunit may ilang bahagi ng role-play na maaaring mas mapalalim. Maayos ang pagganap ng karamihan sa mga miyembro, ngunit may ilang hindi gaanong aktibo. May sapat na malikhaing paggamit ng props at storyline, ngunit hindi lahat ng mga epekto ay ganap na naipakita."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Ang presentasyon ay hindi ganap na malinaw at may kakulangan sa pagpapaliwanag ng aspeto ng globalisasyon. Kulang ang malikhaing elemento, at hindi lahat ng miyembro ay aktibong lumahok. May mga aspeto ng globalisasyon na hindi nabigyan ng diin o hindi sapat na naipakita ang mga epekto."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Hindi malinaw ang mensahe ng role-play at hindi naipakita ang positibo at negatibong epekto ng globalisasyon. Mahina ang storyline, at ang mga miyembro ay hindi aktibong lumahok. Kulang sa malikhaing paggamit ng props o costume, at hindi nakuha ang interes ng mga manonood."
                    },
                ],
            },
            "Advocacy Campaign": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "1. Bumuo ng isang advocacy campaign na tumutukoy sa isang aspeto ng globalisasyon na nais ninyong bigyan ng pansin (pang-ekonomiya, pangteknolohikal, panlipunan, o sosyo-kultural). Maaaring ito ay tungkol sa mga positibo o negatibong epekto ng globalisasyon sa pang-ekonomiya, pangteknolohikal, panlipunan, o sosyo-kultural na aspekto. \n2. Ang advocacy campaign ay dapat maglaman ng mga konkretong hakbang na maaring gawin upang masolusyonan o mapalakas ang mga epekto ng globalisasyon na inyong tinatalakay. \n3. Ang bawat grupo ay bibigyan ng 3-5 minuto upang ipresenta ang kanilang advocacy campaign sa klase. \n4. Siguraduhing malinaw at kapani-paniwala ang mensahe ng inyong kampanya at ito ay makakapukaw ng interes sa mga manonood.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Lubos na makabuluhan ang advocacy campaign; malinaw, kapani-paniwala, at nakakaengganyo ang mensahe. Mahusay ang paggamit ng mga visual aids at angkop ang mga solusyon o hakbang na inilahad upang harapin ang epekto ng globalisasyon. Lahat ng miyembro ay aktibong lumahok, at malinaw ang presentasyon."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Ang kampanya ay malinaw at nauugnay sa paksa, subalit may ilang bahagi na maaaring higit pang mapalalim. Ang mga visual aids ay maayos, ngunit may ilang aspeto ng kampanya na maaaring gawing mas nakakaengganyo. Karamihan ng miyembro ay lumahok, at maayos ang presentasyon."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Ang mensahe ng kampanya ay hindi ganap na malinaw o kapani-paniwala. Kulang sa detalye ang mga solusyon o hakbang upang matugunan ang epekto ng globalisasyon. Ang paggamit ng visual aids ay limitado, at may mga miyembro na hindi aktibong lumahok."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Hindi malinaw ang mensahe ng kampanya, at hindi sapat ang mga hakbang o solusyon sa mga epekto ng globalisasyon. Walang sapat na paggamit ng visual aids, at marami sa mga miyembro ay hindi lumahok nang aktibo. Hindi nakuha ang interes ng mga manonood."
                    },
                ],
            },
            "Action Research": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "1. Bumuo ng isang action research na nakatuon sa isa sa mga epekto ng globalisasyon sa mga aspektong pang-ekonomiya, pangteknolohikal, panlipunan, o sosyo-kultural. Maaari itong tumalakay sa isang problema o oportunidad na dulot ng globalisasyon. \n2. Isama sa inyong research ang mga sumusunod na bahagi: \n• Pamagat ng pananaliksik \n• Paglalahad ng Suliranin (Ano ang problema o oportunidad na nais ninyong tugunan?) \n• Layunin ng Pag-aaral (Ano ang nais ninyong makamit o malaman?) \n• Metodolohiya (Ano ang inyong gagamiting pamamaraan upang masagot ang inyong mga tanong?) \n• Resulta at Kongklusyon (Ano ang inyong natuklasan mula sa pag-aaral?) \n• Rekomendasyon (Ano ang maaaring hakbang o solusyon na maaring gawin batay sa inyong findings?) \n3. I-presenta ang inyong action research sa klase sa loob ng 5-7 minuto, gamit ang mga visual aids o PowerPoint para mas maipaliwanag ang inyong findings at rekomendasyon. \nTemplate: \n1. Title \n2. Abstract (not more than 200 words) \n3. Introduction \n• Statement of the Problem \n• Scope and Delimitation \n• Significance \n• Definition of Terms \n4. Brief review of literature \n5. Methodology and Research Design \n6. Results and Discussion \n7. Conclusion \n8. Reference",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Ang action research ay malinaw na nakatuon sa isang tiyak na epekto ng globalisasyon at nagbibigay ng makabuluhang solusyon o rekomendasyon. Ang paglalahad ay organisado at detalyado, gamit ang tamang metodolohiya. Ang resulta at rekomendasyon ay malinaw na naiugnay sa mga layunin ng pag-aaral. Ang presentasyon ay maayos, may sapat na visual aids, at lahat ng miyembro ay aktibong lumahok."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Malinaw ang research, ngunit may mga bahagi na maaaring higit pang mapalalim. Ang paglalahad ng suliranin at resulta ay maayos, subalit ang rekomendasyon o solusyon ay hindi ganap na detalyado. Gumamit ng visual aids, ngunit may ilang kakulangan sa organisasyon o presentasyon. Karamihan ng mga miyembro ay aktibong lumahok sa presentasyon."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Ang research ay hindi ganap na malinaw o kulang sa detalye, lalo na sa mga layunin, metodolohiya, o resulta. Ang mga solusyon o rekomendasyon ay hindi malinaw o hindi nauugnay sa mga natuklasan. Ang presentasyon ay kulang sa visual aids, at may mga miyembro na hindi ganap na aktibo."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Hindi malinaw ang action research; maraming bahagi ang kulang o hindi sapat na natugunan. Walang malinaw na solusyon o rekomendasyon, at ang presentasyon ay magulo at kulang sa visual aids. Karamihan sa mga miyembro ay hindi lumahok nang aktibo."
                    },
                ],
            },
            "E-Portfolio": {
                objectives: "Naipahahayag ang saloobin tungkol sa epekto ng globalisasyon sa aspektong pang-ekonomiya, pangteknolohikal, panlipunan at sosyo-kultural.",
                instructions: "Gumawa ng isang E-Portfolio na naglalaman ng lahat ng mga outputs at reflections mula sa mga naging gawain sa talakayan tungkol sa globalisasyon at ang mga epekto nito sa iba’t ibang aspeto (pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural).",
                format: "• Introduction \n• Biography of the student \n• Output and quizzes \n• Reflection \n• Documentation \nMaaring gumamit ng iba’t ibang sites tulad ng Google Sites, Powerpoint Presentation, Canva, Wix, etc.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Ang E-Portfolio ay lubos na organisado, kumpleto, at may malalim na nilalaman. Lahat ng bahagi ay malinaw na nag-uugnay sa mga epekto ng globalisasyon, at ang reflections ay makabuluhan. May mahusay na visual design at creative elements na nakatulong upang maghatid ng malinaw na mensahe."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Ang E-Portfolio ay kumpleto at malinaw, subalit may ilang bahagi na maaaring mas mapalalim o mapagbuti, tulad ng reflections o visual design. Organisado ito, ngunit may ilang aspeto na maaaring gawing mas kaakit-akit o madaling sundan."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Ang E-Portfolio ay kulang sa detalye o may nawawalang bahagi. Hindi lahat ng gawain at reflections ay malinaw na naipakita o nanuot ng kahulugan. Kulang sa organisasyon o hindi ganap na kaakit-akit ang presentation."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Hindi kumpleto ang E-Portfolio, at maraming bahagi ang nawawala o hindi malinaw. Walang malinaw na reflections o connections sa mga epekto ng globalisasyon, at walang sapat na visual appeal."
                    },
                ],
            },
        },
    },
    { //ARALIN 8888888888
        title: "Aralin 8: Positibo at Negatibong Saloobin Hinggil sa mga Piling Aspekto ng Globalisasyon",
        activities: {
            "Role play": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: "1. Mahahati ang buong klase sa apat na grupo, at bawat grupo ay gagawa ng isang role-play na nagpapakita ng positibo at negatibong epekto ng globalisasyon sa apat na aspektong tinalakay. \n2. Ang mga grupo ay nahahati ayon sa mga sumusunod na aspeto: \n• Pangkat 1: Pang-ekonomiya \n• Pangkat 2: Pangteknolohikal \n• Pangkat 3: Panlipunan \n• Pangkat 4: Sosyo-kultural \n3. Ang bawat grupo ay magkakaroon lamang ng 5-7 minuto upang ipresenta ang kanilang role-play.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Lubos na makabuluhan at malinaw ang presentasyon ng aspeto ng globalisasyon. Maayos ang storyline, kapansin-pansin ang pagganap ng bawat miyembro, at malikhaing nagamit ang mga props at costume. Ang mga positibo at negatibong epekto ay naipakita nang mahusay, at lubos na nakuha ang atensyon ng mga manonood."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Malinaw ang mensahe at nailahad ang aspeto ng globalisasyon, ngunit may ilang bahagi ng role-play na maaaring mas mapalalim. Maayos ang pagganap ng karamihan sa mga miyembro, ngunit may ilang hindi gaanong aktibo. May sapat na malikhaing paggamit ng props at storyline, ngunit hindi lahat ng mga epekto ay ganap na naipakita."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Ang presentasyon ay hindi ganap na malinaw at may kakulangan sa pagpapaliwanag ng aspeto ng globalisasyon. Kulang ang malikhaing elemento, at hindi lahat ng miyembro ay aktibong lumahok. May mga aspeto ng globalisasyon na hindi nabigyan ng diin o hindi sapat na naipakita ang mga epekto."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Hindi malinaw ang mensahe ng role-play at hindi naipakita ang positibo at negatibong epekto ng globalisasyon. Mahina ang storyline, at ang mga miyembro ay hindi aktibong lumahok. Kulang sa malikhaing paggamit ng props o costume, at hindi nakuha ang interes ng mga manonood."
                    },
                ],
            },
            "Trend Analysis": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: "1. Ipakilala ang Isyu. Sa pagsusuri ng globalisasyon, mahalaga ang pag-unawa sa mga positibo at negatibong epekto nito sa iba't ibang aspeto tulad ng pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural. \n2. Ilarawan ang Trend ng Nakaraang Limang Taon. Gumamit ng linya, bar, o pictograph upang ipakita ang trend ng positibo at negatibong pananaw hinggil sa globalisasyon sa iba't ibang aspeto sa nakalipas na limang taon. \n3. Ipakita ang Proyeksiyon para sa Susunod na Limang Taon. Base sa kasalukuyang datos, maaaring iproject ang posibleng magiging kalakaran sa hinaharap. \n4. Deskripsyon ng Graph. Base sa graph, maaaring ilarawan ang mga positibo at negatibong epekto ng globalisasyon. \n5. Pagsulat ng Konklusyon at Implikasyon. Sa batayan ng trend analysis, malinaw na ang globalisasyon ay nagdudulot ng parehong positibo at negatibong epekto.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Napakahusay na pagkakaintindi sa isyu; malinaw na nailahad ang mga positibo at negatibong saloobin. Maayos at organisado ang trend analysis at ginamit nang wasto ang graph. Komprehensibo ang konklusyon at ang mga implikasyon ay kapaki-pakinabang para sa lipunan at edukasyon."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Magandang presentasyon ng isyu at sapat ang pagkaintindi. Gumamit ng graph ngunit may kaunting kalituhan sa analysis. Ang konklusyon ay may kabuluhan ngunit may mga aspekto na maaaring linawin pa."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Katamtamang pagkaintindi sa isyu; ang presentasyon ng graph ay hindi ganap na organisado. Kulang ang analysis at ang konklusyon ay hindi ganap na kapani-paniwala o may kulang na bahagi."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Kailangan ng malaki pang pag-unlad; hindi malinaw ang presentasyon at hindi naipakita nang wasto ang trend analysis. Hindi rin malinaw o wala ang konklusyon at mga implikasyon."
                    },
                ],
            },
            "Research Paper": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: "1. Panimula: Ipakilala ang napiling aspeto ng globalisasyon. Ipaliwanag ang kahalagahan nito at kung bakit ito napapanahon. \n2. Katawan: \n• Ilarawan ang mga positibong epekto ng globalisasyon sa napiling aspeto. \n• Ilarawan ang mga negatibong epekto ng globalisasyon sa parehong aspeto. \n• Magbigay ng mga halimbawa mula sa kasalukuyang pag-aaral o ulat upang suportahan ang iyong mga argumento. \n3. Pagsusuri: Gumawa ng kritikal na pagsusuri sa mga datos na iyong natipon. I-analyze ang mga epekto ng globalisasyon batay sa nakalap mong impormasyon. \n4. Konklusyon: Ibigay ang iyong pangkalahatang pananaw tungkol sa globalisasyon at ang pangmatagalang implikasyon nito sa napiling aspeto \n5. Sanggunian: Gumamit ng wastong format sa pagkilala sa mga pinagmulan ng impormasyon na iyong ginamit (APA, MLA, o iba pa).",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Ang research paper ay nagpapakita ng mahusay na pagkaintindi sa isyu, malalim na pagsusuri ng mga datos, at kapani-paniwalang konklusyon. Organisado at detalyado ang nilalaman, at ang mga sanggunian ay wasto."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Maayos ang research paper, naipapakita ang pangunahing ideya at pagsusuri, ngunit may ilang bahagi na maaaring palawakin o paghusayin. Ang mga sanggunian ay karaniwan ngunit maaaring iayos pa."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Katamtamang presentasyon; hindi ganap na naipakita ang malalim na pagsusuri at kulang sa detalye ang mga argumento. Ang mga sanggunian ay hindi ganap na wasto o kulang sa detalye."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Kailangan ng malaki pang pag-unlad sa nilalaman, organisasyon, at paggamit ng ebidensya. Hindi malinaw ang paglalatag ng isyu at wala o kulang sa pagsusuri. Mali o walang sanggunian."
                    },
                ],
            },
            "Debate": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: "1. Ang klase ay mahahati sa dalawang pangkat. Ang isang pangkat ay magtatalakay ng positibong epekto ng globalisasyon, habang ang kabilang pangkat ay magtatalakay ng negatibong epekto ng globalisasyon. \n2. Bawat pangkat ay maghahanda ng mga argumento upang suportahan ang kanilang paninindigan. \n3. Sundin ang tamang format ng debate: \n• Panimula ng bawat panig: 2-3 minuto bawat isa. \n• Mga pangunahing argumento: 5-7 minuto bawat panig. \n• Rebuttal: 3-5 minuto bawat panig.\n• Konklusyon: 2-3 minuto bawat panig. \n4. Ang mga miyembro ng bawat grupo ay dapat magtulungan upang makabuo ng malinaw at matibay na argumento. \n5. Gumamit ng mga ebidensya mula sa mga pinag-aralan tungkol sa mga aspeto ng globalisasyon (pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural). \n6. I-submit ang mga ginamit na reference materials pagkatapos ng debate.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Napakahusay ang debate; ang mga argumento ay malinaw, lohikal, at mahusay na naipahayag. Malalim ang pagsusuri sa mga positibo o negatibong epekto ng globalisasyon. Ang bawat miyembro ng grupo ay aktibong nakilahok at mahusay na tumugon sa mga tanong. Ang grupo ay nagpakita ng mataas na antas ng kritikal na pag-iisip at pangangatwiran."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Maganda ang debate; malinaw ang mga argumento at naipahayag nang maayos, ngunit may ilang bahagi na maaaring mas palalimin o linawin pa. Karamihan sa mga miyembro ng grupo ay aktibong nakilahok, at ang mga sagot sa tanong ay naaangkop ngunit may mga pagkakataong kulang sa detalye."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Katamtaman ang debate; may ilang malinaw na argumento ngunit kulang sa lohika o detalye. Hindi lahat ng miyembro ng grupo ay aktibong nakilahok, at may ilang bahagi ng mga sagot na hindi ganap na sumagot sa mga tanong. Kailangan pang pagbutihin ang pagpapahayag at pagsusuri ng mga ideya."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Kailangan ng malaking pag-unlad; mahina ang debate at hindi malinaw ang mga argumento. Karamihan sa mga miyembro ng grupo ay hindi aktibong nakilahok, at ang mga sagot ay hindi tumutugon sa mga tanong o wala sa konteksto. Mahina ang pagpapahayag ng mga ideya at kulang sa lohika o detalye ang mga argumento."
                    },
                ],
            },
            "Scrapbook": {
                objectives: "Naipapahayag ang positibo at negatibong saloobin hinggil sa mga piling aspekto ng globalisasyon.",
                instructions: "Gumawa ng isang scrapbook na naglalaman ng mga larawan, impormasyon, at malikhaing presentasyon hinggil sa mga positibo at negatibong saloobin tungkol sa mga piling aspekto ng globalisasyon (pang-ekonomiya, pangteknolohikal, panlipunan, at sosyo-kultural). \nMga hakbang: \n1. Panimula: Magbigay ng maikling pagpapakilala tungkol sa tema ng scrapbook at ang kahalagahan ng globalisasyon \n2. Nilalaman: Hatiin ang scrapbook sa apat na bahagi (Pang-ekonomiya, Pangteknolohikal, Panlipunan, Sosyo-kultural). Ipakita ang mga positibo at negatibong epekto ng globalisasyon sa bawat aspeto sa pamamagitan ng mga larawan, datos, at mga maikling paliwanag. \n3. Malikhaing Disenyo: Siguraduhing ang bawat pahina ng scrapbook ay malikhaing dinisenyo gamit ang mga larawan, kulay, at iba pang elemento upang mas maging kaakit-akit ang presentasyon. \n4. Konklusyon: Isulat ang iyong buod o konklusyon tungkol sa mga natutunan mo tungkol sa globalisasyon at ang pangmatagalang epekto nito sa mga aspeto ng lipunan. \n5. Paggamit ng Sanggunian: Gumamit ng mga wastong sanggunian kung kinakailangan.",
                tableData: [
                    {
                        column1: "Puntos",
                        column2: "Paglalarawan"
                    },
                    {
                        column1: "Napakahusay (4)",
                        column2: "Ang scrapbook ay nagpapakita ng mahusay na pagkaintindi sa mga positibo at negatibong epekto ng globalisasyon. Ang disenyo at nilalaman ay malikhaing nailahad, at may malinaw na konklusyon."
                    },
                    {
                        column1: "Mahusay (3)",
                        column2: "Maayos ang scrapbook, may malinaw na pagpapakita ng mga epekto ng globalisasyon ngunit maaaring paghusayin pa ang ilang bahagi ng disenyo o nilalaman."
                    },
                    {
                        column1: "Katamtaman (2)",
                        column2: "Katamtaman ang scrapbook, kulang sa detalye at malikhaing presentasyon. Hindi ganap na naipakita ang mga epekto ng globalisasyon o kulang sa konklusyon."
                    },
                    {
                        column1: "Kailangan ng Pag-unlad (1)",
                        column2: "Kailangan ng malaki pang pag-unlad. Hindi malinaw o hindi organisado ang presentasyon at hindi naipakita nang maayos ang epekto ng globalisasyon."
                    },
                ],
            },
        },
    },

];


function SummativeAssessmentInput() {
    const location = useLocation();
    const { assessment, isEditing, aralinTitle, activityName, aralinId } = location.state || {}; // Get the isEditing flag


    // Find the selected aralin and activity details
    const selectedAralin = aralinData.find((aralin) => aralin.title === aralinTitle);
    const activityDetails = selectedAralin?.activities[activityName];


    const [isMobile, setIsMobile] = useState(false);
    const [userUID, setUserUID] = useState(null); // State to store the current user's UID

    // Initialize your form states with assessment data if available
    const [topicTitle, setTopicTitle] = React.useState(assessment ? assessment.title : "");
    const [objectives, setObjectives] = React.useState(assessment ? assessment.objectives : "");
    const [instructions, setInstructions] = React.useState(assessment ? assessment.instructions : "");
    const [format, setFormat] = React.useState(assessment ? assessment.format : ""); // New state for Format
    const [tableData, setTableData] = useState(
        assessment ? assessment.tableData : activityDetails?.tableData || []
    );

    const [isEditableObjectives, setIsEditableObjectives] = useState(false);
    const [isEditableInstructions, setIsEditableInstructions] = useState(false);
    const [isEditableFormat, setIsEditableFormat] = useState(false);
    const [divSize, setDivSize] = useState({ width: 0, height: 0 });
    const navigate = useNavigate();

    const COLUMN_ORDER = ["column1", "column2", "column3", "column4", "column5"];
    const TWO_COLUMN_ORDER = ["column1", "column2"]; // Define order for two-column tables


    useEffect(() => {
        if (activityDetails) {
            setObjectives(activityDetails.objectives);
            setInstructions(activityDetails.instructions);
            setFormat(activityDetails.format || ""); // Set format if available
            setTableData(activityDetails.tableData);
        }
    }, [activityDetails]);

    // Responsive UI
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia("(max-width: 600px)").matches);
        };

        handleResize(); // Initialize state
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (selectedAralin && activityDetails) {
            setObjectives(activityDetails.objectives || "No objectives found.");
            setInstructions(activityDetails.instructions || "No instructions found.");
        }
    }, [selectedAralin, activityDetails]);


    useEffect(() => {
        const orderedTableData = (data) => {
            const isTwoColumnTable = data[0] && Object.keys(data[0]).length === 2;
            const order = isTwoColumnTable ? TWO_COLUMN_ORDER : COLUMN_ORDER;

            return data.map((row) => {
                const orderedRow = {};
                order.forEach((col) => {
                    orderedRow[col] = row[col] || "";
                });
                return orderedRow;
            });
        };

        if (isEditing && assessment) {
            // Load existing assessment data in edit mode
            setTopicTitle(assessment.title || "");
            setObjectives(assessment.objectives || "");
            setInstructions(assessment.instructions || "");
            setFormat(assessment.format || ""); // Load format if available
            setTableData(assessment.tableData || []);
        } else if (selectedAralin) {
            setTopicTitle(selectedAralin.title);
            setObjectives(activityDetails?.objectives || "");
            setInstructions(activityDetails?.instructions || "");
            setFormat(activityDetails?.format || ""); // Load format only if available in aralinData
            setTableData(activityDetails?.tableData || []);
        }
    }, [isEditing, assessment, selectedAralin, activityDetails]);




    function throttle(func, delay) {
        let lastCall = 0;
        return function (...args) {
            const now = Date.now();
            if (now - lastCall >= delay) {
                lastCall = now;
                func(...args);
            }
        };
    }

    // ResizeObserver with throttling
    useEffect(() => {
        const divElem = document.querySelector("#resize-observed-div");
        const throttledSetDivSize = throttle((entry) => {
            setDivSize({
                width: entry.contentRect.width,
                height: entry.contentRect.height,
            });
            console.log("Div resized:", entry.contentRect);
        }, 100); // Adjust throttle delay as needed

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                throttledSetDivSize(entry);
            }
        });

        if (divElem) resizeObserver.observe(divElem);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);



    const handleAddRow = () => {
        const newRow = {};
        Object.keys(tableData[0] || { column1: "", column2: "" }).forEach(col => {
            newRow[col] = ""; // Initialize each cell with an empty string
        });
        setTableData([...tableData, newRow]);
    };

    const handleRemoveRow = (index) => {
        if (tableData.length > 1) {
            setTableData(tableData.filter((_, i) => i !== index));
        }
    };

    const handleCellChange = (rowIndex, colName, value) => {
        setTableData(prevData =>
            prevData.map((row, idx) =>
                idx === rowIndex ? { ...row, [colName]: value } : row
            )
        );
    };


    // Check for authenticated user and get UID
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserUID(user.uid); // Set UID for the logged-in user
            } else {
                console.warn("User is not authenticated");
            }
        });
        return () => unsubscribe(); // Cleanup listener on component unmount
    }, []);

    const handleSaveOrUpdate = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to save this summative assessment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, save it!'
        });

        if (result.isConfirmed) {
            if (!userUID) {
                console.error("User UID not found. Please log in.");
                return;
            }

            try {
                const activityName = location.state?.activityName || "Default Activity Name";
                const dataToSave = {
                    title: topicTitle,
                    objectives,
                    instructions,
                    tableData,
                    updatedAt: serverTimestamp(),
                    createdBy: userUID,
                    activityName
                };

                if (format) dataToSave.format = format; // Only add format if it's available

                if (isEditing && assessment?.id) {
                    const docRef = doc(db, "summativeAssessments", assessment.id);
                    await updateDoc(docRef, dataToSave);
                } else {
                    dataToSave.createdAt = serverTimestamp();
                    await addDoc(collection(db, "summativeAssessments"), dataToSave);
                }
                Swal.fire('Saved!', 'Your summative assessment has been saved.', 'success');
                navigate("/assessment-records/summative-assessment");
            } catch (error) {
                console.error("Error saving summative assessment:", error);
                Swal.fire('Error!', 'Failed to save summative assessment.', 'error');
            }
        }
    };

    // Dynamic Table Rendering
    const columns = COLUMN_ORDER.filter((col) => tableData[0]?.hasOwnProperty(col));



    return (
        <Box id="resize-observed-div" sx={{ mt: isMobile ? 12 : 14, px: isMobile ? 2 : 6 }}>
            <Typography variant="h4" fontWeight="bold" fontFamily="Poppins" textAlign="center" mb={2}>
                SUMMATIVE ASSESSMENT
            </Typography>
            <Typography variant="h5" fontFamily="Poppins" fontWeight="medium" textAlign="center" mb={4}>
                (Aralin {aralinId} - {activityName})
            </Typography>

            <Grid container spacing={3} justifyContent="center">
                {/* TITLE OF TOPIC (PAKSA) */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            TITLE OF TOPIC (PAKSA):
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={topicTitle}
                            disabled
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: !isEditableObjectives ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                },
                                "& .MuiOutlinedInput-root.Mui-disabled": {
                                    backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                },
                                "& .MuiOutlinedInput-root fieldset": {
                                    borderColor: "black",
                                    borderWidth: "2px",
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                {/* TYPE OF TASK */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            TYPE OF TASK:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h5" fontFamily="Poppins" fontWeight="bold" color="#870400">
                            {activityName}
                        </Typography>
                    </Grid>
                </Grid>
                {/* Objectives Section */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            OBJECTIVES (LAYUNIN):
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ position: "relative" }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                value={objectives}
                                onChange={(e) => setObjectives(e.target.value)}
                                disabled={!isEditableObjectives}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        backgroundColor: !isEditableObjectives ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled": {
                                        backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                    },
                                    "& .MuiOutlinedInput-root fieldset": {
                                        borderColor: "black",
                                        borderWidth: "2px",
                                    },
                                }}
                            />
                            <Button
                                variant="text"
                                sx={{
                                    position: "absolute",
                                    bottom: 8,
                                    right: 8,
                                    textTransform: "none",
                                    textDecoration: "underline", // Underline the button text
                                    "&:hover": {
                                        textDecoration: "underline", // Maintain underline on hover
                                    },
                                }}
                                onClick={() => setIsEditableObjectives(!isEditableObjectives)}
                            >
                                {isEditableObjectives ? "Save" : "Edit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                {/* Instructions Section */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                            INSTRUCTIONS (PANUTO):
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box sx={{ position: "relative" }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                                disabled={!isEditableInstructions}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        backgroundColor: !isEditableObjectives ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled": {
                                        backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                    },
                                    "& .MuiOutlinedInput-root fieldset": {
                                        borderColor: "black",
                                        borderWidth: "2px",
                                    },
                                }}
                            />
                            <Button
                                variant="text"
                                sx={{
                                    position: "absolute",
                                    bottom: 8,
                                    right: 8,
                                    textTransform: "none",
                                    textDecoration: "underline", // Underline the button text
                                    "&:hover": {
                                        textDecoration: "underline", // Maintain underline on hover
                                    },
                                }}
                                onClick={() => setIsEditableInstructions(!isEditableInstructions)}
                            >
                                {isEditableInstructions ? "Save" : "Edit"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                {format && (
                    <Grid item xs={12} container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6" fontFamily="Poppins" fontWeight="bold">
                                FORMAT/PORMAT:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box sx={{ position: "relative" }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={format}
                                    onChange={(e) => setFormat(e.target.value)}
                                    disabled={!isEditableFormat}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            backgroundColor: !isEditableFormat ? "#e0e0e0" : "inherit", // Slight dark gray for disabled state
                                        },
                                        "& .MuiOutlinedInput-root.Mui-disabled": {
                                            backgroundColor: "#e0e0e0", // Override MUI's light gray with darker gray
                                        },
                                        "& .MuiOutlinedInput-root fieldset": {
                                            borderColor: "black",
                                            borderWidth: "2px",
                                        },
                                    }}
                                />
                                <Button
                                    variant="text"
                                    sx={{
                                        position: "absolute",
                                        bottom: 8,
                                        right: 8,
                                        textTransform: "none",
                                        textDecoration: "underline", // Underline the button text
                                        "&:hover": {
                                            textDecoration: "underline", // Maintain underline on hover
                                        },
                                    }}
                                    onClick={() => setIsEditableFormat(!isEditableFormat)}
                                >
                                    {isEditableFormat ? "Save" : "Edit"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Grid>


            <Grid container spacing={3} justifyContent="center">
                {/* Rubrics */}
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" fontWeight="bold" sx={{ mt: 5 }}>Rubrics</Typography>
                    </Grid>
                </Grid>

                {/* Dynamic Table */}
                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ mt: 4, width: "fit-content", mx: "auto" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((col, idx) => (
                                        <TableCell key={idx} align="center" sx={{ fontWeight: "bold", fontFamily: "Poppins" }}>{col.toUpperCase()}</TableCell>
                                    ))}
                                    <TableCell align="center" sx={{ fontWeight: "bold", fontFamily: "Poppins" }}>ACTIONS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {columns.map((col, colIndex) => (
                                            <TableCell key={colIndex}>
                                                <TextField
                                                    value={row[col]}
                                                    onChange={(e) => handleCellChange(rowIndex, col, e.target.value)}
                                                    //multiline
                                                    rows={2}
                                                />
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <IconButton onClick={handleAddRow} sx={{ color: 'blue' }}>
                                                    <Add />
                                                </IconButton>
                                                <IconButton onClick={() => handleRemoveRow(rowIndex)} sx={{ color: 'red' }}>
                                                    <Remove />
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                    <Button variant="contained" sx={{ backgroundColor: "#870400" }} onClick={handleSaveOrUpdate}>
                        {isEditing ? "Update" : "Save"}  {/* Change button text based on edit mode */}
                    </Button>
                </Box>

            </Grid>
        </Box >
    );
}

export default SummativeAssessmentInput;
