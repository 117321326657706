import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../config/firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { TextField, Button, Typography, Container, CircularProgress, Box, Grid, useMediaQuery } from '@mui/material';

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const { firstName, lastName, email, password, confirmPassword } = formData;

    // Ensure passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    // Check if email has the correct domain
    const allowedDomain = "@deped.gov.ph";
    if (!email.endsWith(allowedDomain)) {
      setError(`Invalid email address. It must end with ${allowedDomain}`);
      setLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await addDoc(collection(db, 'teacher'), {
        uid: user.uid,
        firstName,
        lastName,
        email
      });

      navigate('/login');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: isMobile ? 'center' : 'space-between',
        backgroundImage: `url('/resources/login_signup_bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        mt: isMobile ? -6 : 0,
        padding: isMobile ? 2 : 0,
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            flex: '0 0 50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 5,
            mt: 5,
            backgroundImage: `url('/resources/kaiba_logo.png')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '100%',
          }}
        />
      )}

      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: '#FFFFFF',
          padding: isMobile ? 3 : 4,
          borderRadius: 2,
          marginTop: isMobile ? 2 : 0,
          mr: isMobile ? 4 : 17,
          width: isMobile ? '90%' : 'auto',
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              color: '#870400',
              fontFamily: 'Poppins',
              fontWeight: 900,
              marginBottom: 2,
              letterSpacing: '0.5rem',
              textShadow: '1px 1px 0px rgba(0,0,0,0.5)',
            }}
          >
            KAIBA
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ fontFamily: 'Poppins', marginBottom: 1, fontWeight: 600 }}
          >
            Create your account
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="password"
                label="Password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          {error && <Typography color="error">{error}</Typography>}
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: '#870400',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#6b0300',
                },
              }}
              fullWidth
              style={{ marginTop: '16px' }}
            >
              Sign Up
            </Button>
          )}
        </form>
        <Typography
          variant="body2"
          align="center"
          sx={{ marginTop: 2, fontFamily: 'Poppins' }}
        >
          Already have an account?{" "}
          <Button
            onClick={() => navigate('/login')}
            sx={{
              textTransform: 'none',
              color: 'blue',
              padding: 0,
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Log in here!
          </Button>
        </Typography>
      </Container>
    </Box>
  );
};

export default SignUp;
