import React from "react";
import { Box, Typography, Container, Divider, useMediaQuery, useTheme } from '@mui/material';

function About() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Queries mobile screens

    return (
        <Container maxWidth="md" sx={{ mt: 14 }}>
            <Box sx={{ textAlign: 'center', mb: 1 }}>
                <Typography variant="h4" component="h1" fontWeight="bold" fontFamily="Poppins">
                    ABOUT
                </Typography>
            </Box>
            <Divider sx={{ mb: 3, backgroundColor: '#FF9D01', height: '3px' }} />
            <Box>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        textAlign: 'justify',
                        fontSize: '1.1rem',
                        lineHeight: 1.7
                    }}
                >
                    <Box component="span" fontStyle="italic">Kaiba</Box> is an innovative project-based assessment tool that helps teachers provide useful project-based assessments. It includes a variety of activities with comprehensive rubrics, all of which correspond well with the lesson's intended learning outcomes. By focusing on practical, outcome-driven tasks, <Box component="span" fontStyle="italic">Kaiba</Box> supports teachers in encouraging deeper learning and crucial skills while also ensuring evaluations are relevant, structured, and meaningful for students' educational journeys.
                </Typography>
            </Box>

            <Box>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        textAlign: 'justify',
                        fontSize: '1.1rem',
                        lineHeight: 1.7
                    }}
                >
                    After years of honing our skills and receiving invaluable support from our instructors at Camarines Norte State College - Abaño Campus, we, the developers, have gained an extensive understanding of the value of project-based assessments in developing students' abilities and overall progress. However, we recognize that the complexity of creating such assessments, along with teachers' heavy workloads and time constraints, frequently results in a reliance on simpler evaluation approaches. This challenge motivated us to design a platform that streamlines the process, allowing instructors to readily and securely conduct relevant project-based assessments.
                </Typography>
            </Box>

            <Box>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        textAlign: 'justify',
                        fontSize: '1.1rem',
                        lineHeight: 1.7
                    }}
                >
                    We named this tool <Box component="span" fontStyle="italic">Kaiba</Box>, a Bikol term that means "companion," to represent its goal of assisting teachers in their teaching endeavors. Through Kaiba, we hope to provide an accessible and efficient platform that not only reduces the burden of assessment design but also assures that students engage in practical, skill-enhancing assignments that are linked with the targeted learning outcomes.
                </Typography>
            </Box>

            {/* New section added */}
            <Box sx={{ mt: 5 }}>
            <Box sx={{ textAlign: 'center', mb: 1 }}>
                <Typography variant="h4" component="h1" fontWeight="bold" fontFamily="Poppins">
                    ABOUT THE DEVELOPERS
                </Typography>
            </Box>
            <Divider sx={{ mb: 3, backgroundColor: '#FF9D01', height: '3px' }} />
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        textAlign: 'justify',
                        fontSize: '1.1rem',
                        lineHeight: 1.7,
                        mt: 2
                    }}
                >
                    We, the developers, are 4th-year students at Camarines Norte State College - Abaño Campus, taking up Bachelor of Secondary Education Major in Social Studies. As aspiring educators, we are committed to improving our abilities, expanding our knowledge, and embodying the ideals necessary to shape and inspire the next generation of learners. Our journey is guided by a passion for education, a commitment to lifelong learning, and a desire to make a significant difference in the lives of our future students and the communities we will serve.
                </Typography>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        textAlign: 'justify',
                        fontSize: '1.1rem',
                        lineHeight: 1.7,
                        mb: 3,
                    }}
                >
                    We aspire to contribute meaningfully to the academic and personal development of our future students while encouraging a culture of lifelong learning, critical thinking, and social responsibility by building on a solid foundation of rigorous training and our instructors’ guidance. We want to be compassionate teachers as well as effective instructors, using education to create a brighter and more inclusive future.
                </Typography>
            </Box>
        </Container>
    );
}

export default About;
