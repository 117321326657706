import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import banner from '../res/banner.png';
import projectImage from '../res/project_based.png';
import importance from '../res/importance.png';
import { motion } from 'framer-motion'; // Import motion for animations

function Home() {
    const [isMobile, setIsMobile] = useState(false);
    const [inView, setInView] = useState(false); // Track if element is in view
    const navigate = useNavigate();
    const sectionRef = useRef(null); // Reference to the section to observe

    // Check if the device is mobile using matchMedia
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia('(max-width: 600px)').matches);
        };
        // Initially set overflow to hidden for horizontal scrollbar
        document.body.style.overflowX = 'hidden';

        // Initial check and add event listener for window resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Cleanup on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Handle scroll event to detect when the section comes into view
    useEffect(() => {
        // Skip IntersectionObserver on mobile and default to inView = true
        if (isMobile) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setInView(entry.isIntersecting); // Set state based on whether it's in view
                console.log('inView:', entry.isIntersecting); // Debugging log
            },
            {
                threshold: 0.5, // Trigger when 50% of the element is visible
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current); // Start observing the section
        }

        // Cleanup observer on component unmount
        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, [isMobile]);

    // Handle button click
    const handleCreateClick = () => {
        navigate('/create-assessment'); // Navigate to the desired route
    };

    return (
        <Box>
            {/* Existing Banner Code */}
            <Box
                sx={{
                    position: 'relative', // Change to relative to allow scrolling
                    width: isMobile ? '105%' : '101.3%',
                    overflow: 'hidden',
                    left: -10,
                    top: 59,
                }}
            >
                <Box
                    component="img"
                    src={banner}
                    alt="Banner"
                    sx={{
                        width: '100%',
                        height: 'auto', // Allow height to be auto for responsiveness
                        objectFit: 'cover',
                        display: 'block',
                    }}
                />
                {/* Text and Button Container */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '80%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        color: 'white',
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleCreateClick}
                        sx={{
                            backgroundColor: '#870400',
                            fontFamily: 'Poppins',
                            mt: isMobile ? 1 : 3,
                            borderRadius: '20px',
                            px: isMobile ? 1 : 9,
                            py: isMobile ? 0.5 : 1.5,
                            fontSize: isMobile ? '0.25rem' : '1rem',
                        }}
                    >
                        CREATE
                    </Button>
                </Box>
            </Box>

            {/* New Content Section with scroll-based framer-motion animations */}
            <Grid container spacing={2} sx={{ marginTop: isMobile ? '60px' : '50px' }}>
                {/* Text Section */}
                <Grid item xs={12} md={6}>
                    <Box padding={2} sx={{ mt: isMobile ? 0 : 5, ml: isMobile ? 0 : 3 }}>
                        <Typography variant="h5" component="div" fontWeight="bold" fontFamily='Poppins' textAlign='center' sx={{ mb: isMobile ? 0 : 3 }}>
                            WHAT IS PROJECT-BASED ASSESSMENT?
                        </Typography>
                        <Typography variant="body1" paragraph component="div" sx={{ marginTop: '10px', textAlign: 'justify' }}>
                            Project-based assessments (PBAs) are the means through which teachers measure student knowledge gained via project-based learning (PBL) — a student-centered teaching approach that uses engaging, real-world applications and hands-on learning to help students build knowledge while strengthening critical thinking and problem-solving skills.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }} >
                            In classrooms that use PBL, students often work together to answer curriculum-relevant questions and solve challenges, preparing them to become adept communicators and collaborators in their future lives and careers. Instead of end-of-unit tests, they are assessed through group or independent projects.
                        </Typography>
                    </Box>
                </Grid>

                {/* Image Section */}
                <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        src={projectImage} // Update with your actual image path
                        alt="Project-based assessment"
                        sx={{
                            width: '60%', // Ensure it doesn't exceed the container width
                            height: 'auto', // Maintain aspect ratio
                            maxWidth: isMobile ? '100%' : '500px', // Limit max width on larger screens
                            ml: isMobile ? 8 : 17,
                            mt: isMobile ? -3 : 2,
                        }}
                    />
                </Grid>
            </Grid>

            {/* Divider after Content */}
            <Divider sx={{ margin: '20px 0', backgroundColor: '#FF9D01', height: '3px' }} />

            {/* Importance of Project-Based Section */}
            <Grid container spacing={2} sx={{ marginTop: isMobile ? '40px' : '10px' }} ref={sectionRef}>
                {/* Image Section on the Left */}
                <Grid item xs={12} md={6}>
                    {isMobile ? (
                        <Box
                            component="img"
                            src={importance}
                            alt="Project-based assessment"
                            sx={{
                                width: '60%',
                                height: 'auto',
                                maxWidth: isMobile ? '100%' : '500px',
                                ml: isMobile ? 8 : 7,
                                mt: isMobile ? -3 : 2,
                            }}
                        />
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, x: -50 }} // Start off-screen to the left
                            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }} // Fade and move to normal position when in view
                            transition={{ duration: 1 }}
                        >
                            <Box
                                component="img"
                                src={importance}
                                alt="Project-based assessment"
                                sx={{
                                    width: '60%',
                                    height: 'auto',
                                    maxWidth: isMobile ? '100%' : '500px',
                                    ml: isMobile ? 8 : 7,
                                    mt: isMobile ? -3 : 7,
                                }}
                            />
                        </motion.div>
                    )}
                </Grid>

                {/* Accordion Section */}
                <Grid item xs={12} md={6}>
                    {isMobile ? (
                        <Box padding={2} sx={{ mt: isMobile ? 0 : 1, ml: isMobile ? 0 : -10, mr: isMobile ? 0 : 9, mb: 4 }}>
                            <Typography variant="h5" component="div" fontWeight="bold" fontFamily='Poppins' textAlign='center' sx={{ mb: isMobile ? 0 : 3 }}>
                                IMPORTANCE OF PROJECT-BASED ASSESSMENT
                            </Typography>

                            {/* Accordion Items */}
                            {['Develops 21st-Century Skills', 'Real-World Learning', 'Enhances Communication', 'Fosters Collaboration', 'Promotes Creativity and Critical Thinking'].map((title, index) => (
                                <Accordion
                                    key={index}
                                    sx={{
                                        boxShadow: 'none',
                                        '&:not(:last-of-type)': {
                                            borderBottom: '1px solid #ccc'
                                        },
                                        '&:before': {
                                            display: 'none'
                                        } // Removes default divider above
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1.2rem', // Bigger font for summary
                                            backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                                            padding: '12px 16px',
                                        }}
                                    >
                                        <Typography fontWeight="bold" fontFamily="Poppins" sx={{ fontSize: '1.1rem' }}>
                                            {title}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            fontSize: '1rem', // Smaller font for details
                                            padding: '12px 16px',
                                            color: '#555', // Muted text color for details
                                        }}
                                    >
                                        <Typography fontFamily="Poppins">
                                            {index === 0 && 'Project-based assessments foster the "4Cs" of 21st-century education: communication, collaboration, creativity, and critical thinking.'}
                                            {index === 1 && 'Projects provide students with hands-on experiences mirroring real-world issues that promote practical application of knowledge.'}
                                            {index === 2 && 'Students can explain findings, articulate ideas, and communicate with different audiences in improving their communication skills.'}
                                            {index === 3 && 'Collaboration is often required, teaching students to work effectively together and value diverse perspectives.'}
                                            {index === 4 && 'Open-ended projects encourage innovative thinking, problem-solving, and informed conclusions.'}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, x: 100 }} // Start off-screen to the right
                            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 100 }} // Fade and move to normal position when in view
                            transition={{ duration: 1 }}
                        >
                            <Box padding={2} sx={{ mt: isMobile ? 0 : 1, ml: isMobile ? 0 : -10, mr: isMobile ? 0 : 9, mb: 4 }}>
                                <Typography variant="h5" component="div" fontWeight="bold" fontFamily='Poppins' textAlign='center' sx={{ mb: isMobile ? 0 : 3 }}>
                                    IMPORTANCE OF PROJECT-BASED ASSESSMENT
                                </Typography>

                                {/* Accordion Items */}
                                {['Develops 21st-Century Skills', 'Real-World Learning', 'Enhances Communication', 'Fosters Collaboration', 'Promotes Creativity and Critical Thinking'].map((title, index) => (
                                    <Accordion
                                        key={index}
                                        sx={{
                                            boxShadow: 'none',
                                            '&:not(:last-of-type)': {
                                                borderBottom: '1px solid #ccc'
                                            },
                                            '&:before': {
                                                display: 'none'
                                            } // Removes default divider above
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem', // Bigger font for summary
                                                backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                                                padding: '12px 16px',
                                            }}
                                        >
                                            <Typography fontWeight="bold" fontFamily="Poppins" sx={{ fontSize: '1.1rem' }}>
                                                {title}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            sx={{
                                                fontSize: '1rem', // Smaller font for details
                                                padding: '12px 16px',
                                                color: '#555', // Muted text color for details
                                            }}
                                        >
                                            <Typography fontFamily="Poppins">
                                                {index === 0 && 'Project-based assessments foster the "4Cs" of 21st-century education: communication, collaboration, creativity, and critical thinking.'}
                                                {index === 1 && 'Projects provide students with hands-on experiences mirroring real-world issues that promote practical application of knowledge.'}
                                                {index === 2 && 'Students can explain findings, articulate ideas, and communicate with different audiences in improving their communication skills.'}
                                                {index === 3 && 'Collaboration is often required, teaching students to work effectively together and value diverse perspectives.'}
                                                {index === 4 && 'Open-ended projects encourage innovative thinking, problem-solving, and informed conclusions.'}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </Box>
                        </motion.div>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Home;
