import React from "react";
import { Grid, Card, CardContent, Typography, Avatar, Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import { blue, red, grey } from "@mui/material/colors";
import { AccountCircle, Home, Create, Save, History, CloudDownload, Info, Person } from "@mui/icons-material";

const steps = [
    {
        number: 1,
        title: "Signing Up and Logging In",
        description: `
      - Create an account by signing up with your details.
      - Log in using your **credentials** to access the platform.
    `,
        icon: <AccountCircle />,
    },
    {
        number: 2,
        title: "Home Page",
        description: `
      - After logging in, you will be directed to the **Home Page**.
      - The Home Page provides an **overview** of the website and highlights its purpose.
    `,
        icon: <Home />,
    },
    {
        number: 3,
        title: "Creating an Assessment",
        description: `
      - Navigate to the **"Create Assessment"** section.
      - Choose the type of assessment: **Formative** or **Summative**.
    `,
        icon: <Create />,
    },
    {
        number: 4,
        title: "Formative/Summative Assessment",
        description: `
      - Choose an **activity type**, then select a specific lesson or task.
      - Review the **topic**, task, instructions, and rubrics. All content is **editable** for customization.
    `,
        icon: <Create />,
    },
    {
        number: 5,
        title: "Saving Assessments",
        description: `
      - Once finished, save your work by clicking the **Save it** and **OK** button.
      - The saved assessment will automatically appear in the **History** section or **Assessment Records**.
    `,
        icon: <Save />,
    },
    {
        number: 6,
        title: "Accessing Assessment Records",
        description: `
      - Go to **Assessment Records** and select the type of assessment.
      - Use the icons to **Edit** (make changes), **Download** (save), or **Delete** (remove) the assessment.
    `,
        icon: <History />,
    },
    {
        number: 7,
        title: "Downloading Assessments",
        description: `
      - After downloading, a **notification** will appear.
      - Click the notification to open the **printable version**, which includes the topic, activity, instructions, and rubrics.
    `,
        icon: <CloudDownload />,
    },
    {
        number: 8,
        title: "About Section",
        description: `
      - Click the **"About"** section to view the **background** and history of the website.
      - You can also find **contact information** for the owner(s) here.
    `,
        icon: <Info />,
    },
    {
        number: 9,
        title: "Profile Logo",
        description: `
        - Click the **profile logo** to access settings:  
        - **Personal Settings**: Update your details.  
        - **Assessment Record**: View account history.  
        - **About**: Learn about the website.  
        - **User's Guide**: Step-by-step instructions for using the platform.  
        - **Log Out**: Exit your account.
    `,
        icon: <Person />,
    },
];


// Utility function to bold specific words wrapped in `**`
const formatDescription = (description) => {
    return description.split("\n").map((line, index) => (
        <div key={index}>
            {line.split(/(\*\*.*?\*\*)/).map((chunk, i) =>
                chunk.startsWith("**") && chunk.endsWith("**") ? (
                    <b key={i}>{chunk.replace(/\*\*/g, "")}</b>
                ) : (
                    chunk
                )
            )}
        </div>
    ));
};

const UserGuide = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Define isMobile based on screen size

    return (
        <Box sx={{ p: 4, fontFamily: "Poppins" }}>
            <Box sx={{ textAlign: 'center', mb: 1, mt: 8 }}>
                <Typography variant="h4" component="h1" fontWeight="bold" fontFamily="Poppins">
                    USER'S GUIDE
                </Typography>
            </Box>
            <Divider sx={{ mb: 3, backgroundColor: '#FF9D01', height: '3px' }} />
            <Grid container spacing={3}>
                {steps.map((step) => (
                    <Grid item xs={12} sm={6} md={4} key={step.number}>
                        <Card
                            sx={{
                                height: "350px", // Uniform height
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between", // Evenly space out contents
                                textAlign: "center",
                                p: 2,
                                background: `linear-gradient(to bottom right, ${blue[100]}, ${grey[50]})`,
                                borderRadius: "15px",
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                                transition: "transform 0.2s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                },
                            }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: blue[500],
                                    width: 56,
                                    height: 56,
                                    mx: "auto", // Centers avatar horizontally
                                    mt: 2,
                                }}
                            >
                                {step.icon}
                            </Avatar>
                            <CardContent sx={{ fontFamily: "Poppins", flexGrow: 1 }}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{ fontWeight: 500, mb: isMobile ? 0 : 2, fontSize: "1.1rem" }}
                                >
                                    {`Step ${step.number}: ${step.title}`}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    component="div"
                                    sx={{
                                        fontFamily: "Poppins",
                                        textAlign: "left",
                                        lineHeight: 1.6,
                                    }}
                                >
                                    {formatDescription(step.description)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default UserGuide;
