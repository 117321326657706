import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Drawer, List, ListItem, ListItemText, Divider, useMediaQuery, useTheme, Menu, MenuItem } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { RiAccountCircleFill } from "react-icons/ri";
import { GiCancel } from "react-icons/gi";
import kaibaLogo from '../res/kaiba_logo.png';
import cnscLogo from '../res/cnsc_logo.png';
import { auth } from '../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getUserDetails, logout } from '../auth';

function Navbar() {
  const [isMainDrawerOpen, setIsMainDrawerOpen] = useState(false);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const [assessmentAnchorEl, setAssessmentAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const [userName, setUserName] = useState('User');

  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDetails = await getUserDetails(user.uid);
        if (userDetails) {
          setUserName(userDetails.firstName);
        } else {
          setUserName('User');
        }
      } else {
        setUserName('User');
      }
    });
    return () => unsubscribe();
  }, []);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  useEffect(() => {
    // Map current path to active menu item
    const pathToMenuMap = {
      '/': 'HOME',
      '/create-assessment': 'CREATE ASSESSMENT',
      '/about': 'ABOUT',
      '/assessment-records/formative-assessment': 'ASSESSMENT RECORDS',
      '/assessment-records/summative-assessment': 'ASSESSMENT RECORDS',
    };

    const active = pathToMenuMap[location.pathname] || '';
    setActiveItem(active);
  }, [location]);


  const toggleMainDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsMainDrawerOpen(open);
  };

  const toggleProfileDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsProfileDrawerOpen(open);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleAssessmentClick = (event) => {
    setAssessmentAnchorEl(assessmentAnchorEl ? null : event.currentTarget);
  };

  const handleAssessmentClose = () => {
    setAssessmentAnchorEl(null);
  };

  const handleAssessmentOptionClick = (option) => {
    setActiveItem('ASSESSMENT RECORDS');
    setAssessmentAnchorEl(null);
    // Navigate to the selected assessment type
    navigate(`/assessment-records/${option.toLowerCase().replace(/\s+/g, '-')}`);
  };

  const renderMainDrawerContent = () => (
    <Box
      sx={{
        width: 300,
        height: '100%',
        backgroundColor: '#870400',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
      }}
      role="presentation"
    >
      <IconButton
        onClick={toggleMainDrawer(false)}
        sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
      >
        <GiCancel />
      </IconButton>

      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: 0,
        }}
      >
        {/* HOME and CREATE ASSESSMENT */}
        {['HOME', 'CREATE ASSESSMENT'].map((menu, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              component={Link}
              to={`/${menu.toLowerCase().replace(/\s+/g, '-')}`}
              onClick={() => {
                handleItemClick(menu);
                toggleMainDrawer(false)();
              }}
              sx={{
                color: activeItem === menu ? '#FF9D01' : 'white',
                width: '80%',
                textAlign: 'center',
                mt: 1,
                mb: 1,
                fontFamily: 'Poppins',
              }}
            >
              <ListItemText primary={menu} />
            </ListItem>
            <Divider sx={{ backgroundColor: 'white', width: '80%' }} />
          </React.Fragment>
        ))}

        {/* ASSESSMENT RECORDS with Dropdown */}
        <ListItem
          button
          onClick={toggleDropdown}
          sx={{
            color: activeItem === 'ASSESSMENT RECORDS' ? '#FF9D01' : 'white',
            width: '80%',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontFamily: 'Poppins',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ListItemText primary="ASSESSMENT RECORDS" />
          {openDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItem>

        {openDropdown && (
          <Box sx={{ width: '80%', ml: 2 }}>
            <ListItem
              button
              onClick={() => {
                handleItemClick('Formative Assessment');
                navigate('/assessment-records/formative-assessment');
                toggleMainDrawer(false)();
              }}
              sx={{ color: 'white', fontFamily: 'Poppins' }}
            >
              <ListItemText primary="Formative Assessment" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                handleItemClick('Summative Assessment');
                navigate('/assessment-records/summative-assessment');
                toggleMainDrawer(false)();
              }}
              sx={{ color: 'white', fontFamily: 'Poppins' }}
            >
              <ListItemText primary="Summative Assessment" />
            </ListItem>
          </Box>
        )}

        <Divider sx={{ backgroundColor: 'white', width: '80%' }} />

        {/* ABOUT */}
        <ListItem
          button
          component={Link}
          to="/about"
          onClick={() => {
            handleItemClick('ABOUT');
            toggleMainDrawer(false)();
          }}
          sx={{
            color: activeItem === 'ABOUT' ? '#FF9D01' : 'white',
            width: '80%',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontFamily: 'Poppins',
          }}
        >
          <ListItemText primary="ABOUT" />
        </ListItem>
      </List>
    </Box>
  );


  const renderProfileDrawerContent = () => (
    <Box
      sx={{
        width: 300,
        height: '100%',
        backgroundColor: '#870400',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
      }}
      role="presentation"
    >
      <IconButton
        onClick={toggleProfileDrawer(false)}
        sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
      >
        <GiCancel />
      </IconButton>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: 2,
          padding: 1,
        }}
      >
        <RiAccountCircleFill size={80} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Welcome, {userName}!
        </Typography>
      </Box>

      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: 0,
        }}
      >
        {/* Profile Settings */}
        <ListItem
          button
          onClick={() => {
            handleItemClick('Profile Settings');
            toggleProfileDrawer(false)();
            navigate('/profile-settings');
          }}
          sx={{
            color: activeItem === 'Profile Settings' ? '#FF9D01' : 'white',
            width: '80%',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontFamily: 'Poppins',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(255, 157, 1, 0.2)',
            },
          }}
        >
          <ListItemText primary="Profile Settings" />
        </ListItem>
        <Divider sx={{ backgroundColor: 'white', width: '80%' }} />

        {/* ASSESSMENT RECORDS with Dropdown */}
        <ListItem
          button
          onClick={toggleDropdown}
          sx={{
            color: activeItem === 'ASSESSMENT RECORDS' ? '#FF9D01' : 'white',
            width: '80%',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontFamily: 'Poppins',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ListItemText primary="Assessment Records" />
          {openDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItem>

        {openDropdown && (
          <Box sx={{ width: '80%', ml: 2 }}>
            <ListItem
              button
              onClick={() => {
                handleItemClick('Formative Assessment');
                navigate('/assessment-records/formative-assessment');
                toggleProfileDrawer(false)();
              }}
              sx={{ color: 'white', fontFamily: 'Poppins' }}
            >
              <ListItemText primary="Formative Assessment" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                handleItemClick('Summative Assessment');
                navigate('/assessment-records/summative-assessment');
                toggleProfileDrawer(false)();
              }}
              sx={{ color: 'white', fontFamily: 'Poppins' }}
            >
              <ListItemText primary="Summative Assessment" />
            </ListItem>
          </Box>
        )}

        <Divider sx={{ backgroundColor: 'white', width: '80%' }} />

        {/* About */}
        <ListItem
          button
          onClick={() => {
            handleItemClick('About');
            toggleProfileDrawer(false)();
            navigate('/about');
          }}
          sx={{
            color: activeItem === 'About' ? '#FF9D01' : 'white',
            width: '80%',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontFamily: 'Poppins',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(255, 157, 1, 0.2)',
            },
          }}
        >
          <ListItemText primary="About" />
        </ListItem>
        <Divider sx={{ backgroundColor: 'white', width: '80%' }} />

        {/* User's Guide */}
        <ListItem
          button
          onClick={() => {
            handleItemClick("User's Guide");
            toggleProfileDrawer(false)();
            navigate('/users-guide');
          }}
          sx={{
            color: activeItem === "User's Guide" ? '#FF9D01' : 'white',
            width: '80%',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontFamily: 'Poppins',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(255, 157, 1, 0.2)',
            },
          }}
        >
          <ListItemText primary="User's Guide" />
        </ListItem>
        <Divider sx={{ backgroundColor: 'white', width: '80%' }} />

        {/* Logout */}
        <ListItem
          button
          onClick={handleLogout}
          sx={{
            color: 'white',
            width: '80%',
            textAlign: 'center',
            mt: 1,
            mb: 1,
            fontFamily: 'Poppins',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(255, 157, 1, 0.2)',
            },
          }}
        >
          <ListItemText primary="Logout" />
        </ListItem>
      </List>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
        <img src={cnscLogo} alt="CNSC" style={{ height: '50px' }} />
      </Box>
    </Box>
  );

  return (
    <AppBar position="fixed" sx={{ width: '100%', backgroundColor: '#972D2C' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img src={kaibaLogo} alt="KAIBA" style={{ height: '50px', marginTop: '8px' }} />
          </Link>
        </Typography>

        {isMobile ? (
          <Box>
            <IconButton
              size="large"
              aria-label="show more"
              onClick={toggleMainDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 7 }}>
            {/* HOME, CREATE ASSESSMENT, and ABOUT Menu Items */}
            {['HOME', 'CREATE ASSESSMENT'].map((menu, index) => (
              <MenuItem
                key={index}
                component={Link}
                to={menu === 'HOME' ? '/' : `/${menu.toLowerCase().replace(/\s+/g, '-')}`}
                onClick={() => setActiveItem(menu)}
                sx={{
                  color: activeItem === menu ? '#FF9D01' : 'white',
                  mr: 5,
                  fontFamily: 'Poppins',
                }}
              >
                {menu}
              </MenuItem>
            ))}

            {/* ASSESSMENT RECORDS with Dropdown */}
            <MenuItem
              onClick={handleAssessmentClick}
              sx={{
                color: activeItem === 'ASSESSMENT RECORDS' ? '#FF9D01' : 'white',
                mr: 5,
                fontFamily: 'Poppins',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              ASSESSMENT RECORDS
              <ArrowDropDownIcon />
            </MenuItem>

            <Menu
              anchorEl={assessmentAnchorEl}
              open={Boolean(assessmentAnchorEl)}
              onClose={handleAssessmentClose}
              sx={{
                mt: 1,
                '& .MuiPaper-root': {
                  backgroundColor: '#972D2C', // Replace 'navBarColor' with your navbar's color
                },
              }}
            >
              <MenuItem
                onClick={() => handleAssessmentOptionClick('Formative Assessment')}
                sx={{ color: 'white' }} // Set text color to contrast with the background
              >
                Formative Assessment
              </MenuItem>
              <MenuItem
                onClick={() => handleAssessmentOptionClick('Summative Assessment')}
                sx={{ color: 'white' }}
              >
                Summative Assessment
              </MenuItem>
            </Menu>

            {/* ABOUT Menu Item */}
            <MenuItem
              component={Link}
              to="/about"
              onClick={() => setActiveItem('ABOUT')}
              sx={{
                color: activeItem === 'ABOUT' ? '#FF9D01' : 'white',
                mr: 5,
                fontFamily: 'Poppins',
              }}
            >
              ABOUT
            </MenuItem>
          </Box>

        )}

        <IconButton
          size="large"
          edge="end"
          aria-label="account of current user"
          onClick={toggleProfileDrawer(true)}
          color="inherit"
        >
          <RiAccountCircleFill size={30} />
        </IconButton>

        <Drawer anchor="right" open={isMainDrawerOpen} onClose={toggleMainDrawer(false)}>
          {renderMainDrawerContent()}
        </Drawer>

        <Drawer anchor="right" open={isProfileDrawerOpen} onClose={toggleProfileDrawer(false)}>
          {renderProfileDrawerContent()}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
