import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, CircularProgress, Grid } from '@mui/material';
import { auth, db } from '../config/firebase';
import { getUserDetails } from '../auth';
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import Swal from 'sweetalert2';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";

const ProfileSettings = () => {
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
    });
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        const fetchUserDetails = async () => {
            setLoading(true);
            auth.onAuthStateChanged(async (user) => {
                if (user) {
                    const details = await getUserDetails(user.uid);
                    if (details) {
                        setUserDetails({
                            firstName: details.firstName,
                            lastName: details.lastName,
                            email: details.email,
                            username: details.username || '',
                        });
                    } else {
                        console.log("No user details found for this UID.");
                    }
                } else {
                    console.log('No user is currently logged in.');
                }
                setLoading(false);
            });
        };

        fetchUserDetails();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handlePasswordUpdate = async () => {
        if (newPassword !== confirmPassword) {
            Swal.fire("Error", "New password and confirm password do not match.", "error");
            return;
        }

        try {
            const user = auth.currentUser;
            if (user) {
                const credential = EmailAuthProvider.credential(userDetails.email, oldPassword);
                await reauthenticateWithCredential(user, credential);
                await updatePassword(user, newPassword);
                Swal.fire("Success", "Password updated successfully.", "success");
            }
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                Swal.fire("Error", "The old password is incorrect.", "error");
            } else {
                Swal.fire("Error", "Failed to update password. Please try again later.", "error");
            }
        }
    };

    const handleSave = async () => {
        setSaving(true);
        const user = auth.currentUser;

        if (user) {
            try {
                const teacherQuery = query(
                    collection(db, "teacher"),
                    where("uid", "==", user.uid)
                );

                const querySnapshot = await getDocs(teacherQuery);

                if (!querySnapshot.empty) {
                    const teacherDoc = querySnapshot.docs[0];

                    await updateDoc(teacherDoc.ref, {
                        firstName: userDetails.firstName,
                        lastName: userDetails.lastName,
                        email: userDetails.email,
                        username: userDetails.username,
                    });

                    console.log('User details updated:', userDetails);
                    Swal.fire("Success", "Profile updated successfully.", "success"); // Show success alert
                } else {
                    console.log("No document found with matching UID.");
                }

                setSaving(false);
            } catch (error) {
                console.error("Error updating user details:", error);
                setSaving(false);
                Swal.fire("Error", "Failed to update profile. Please try again later.", "error");
            }
        }
    };

    const handleUpdateClick = async () => {
        await handleSave(); // Call handleSave first
        // Only call handlePasswordUpdate if password fields are filled
        if (oldPassword || newPassword || confirmPassword) {
            await handlePasswordUpdate();
        }
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box sx={{ width: '85%', mx: 'auto', mt: 13, p: 3, border: '1px solid #ccc', borderRadius: '8px' }}>
            <Typography variant="h4" gutterBottom>
                Profile Settings
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="First Name"
                        name="firstName"
                        value={userDetails.firstName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Last Name"
                        name="lastName"
                        value={userDetails.lastName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Email"
                        name="email"
                        value={userDetails.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Username"
                        name="username"
                        value={userDetails.username}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Old Password"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button
                        variant="contained"
                        onClick={handleUpdateClick} // Call the new function
                        disabled={saving}
                        sx={{
                            mt: 2,
                            mb: 2,
                            backgroundColor: "#870400",
                            '&:hover': {
                                backgroundColor: "#5e0300",
                            }
                        }}
                    >
                        {saving ? <CircularProgress size={24} /> : "Update"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProfileSettings;
