// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5S3asNeFBn5u4BtphDiR9Z6NZr-Fcr6o",
  authDomain: "kaiba-2bf0f.firebaseapp.com",
  projectId: "kaiba-2bf0f",
  storageBucket: "kaiba-2bf0f.firebasestorage.app",
  messagingSenderId: "131418932614",
  appId: "1:131418932614:web:cb2e4785e57cba8c799807"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); // Initialize Firebase Authentication