import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Typography, Box, Grid, Card, CardContent } from "@mui/material";
import formativeImage from '../res/formative_assessment.png';
import summativeImage from '../res/summative_assessment.png';
import formative from '../res/formative.png';
import summative from '../res/summative.png';

function CreateAssessment() {
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate

    // Check if the device is mobile using matchMedia
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia('(max-width: 600px)').matches);
        };

        // Initial check and add event listener for window resize
        handleResize();
        window.addEventListener('resize', handleResize);

        // Cleanup on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Function to handle card click navigation
    const handleCardClick = (path) => {
        navigate(path);
    };

    const cardStyle = (bgImage) => ({
        position: 'relative',
        backgroundImage: `url("${bgImage}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: isMobile ? 150 : 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#FF9D0199',
            opacity: 0,
            transition: 'opacity 0.3s',
            zIndex: 1,
        },
        '&:hover::before': {
            opacity: 1,
        },
    });

    return (
        <Box>
            {/* Title */}
            <Typography
                variant="h4"
                component="div"
                fontWeight="bold"
                fontFamily="Poppins"
                textAlign="center"
                sx={{ mt: isMobile ? 12 : 14 }}
            >
                CHOOSE THE TYPE OF ASSESSMENT
            </Typography>

            {/* Cards Section */}
            <Grid
                container
                spacing={3}
                sx={{ mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
                {/* Formative Assessment Card */}
                <Grid item xs={12} md={5}>
                    <Card 
                        sx={cardStyle(formative)}
                        onClick={() => handleCardClick("/create-assessment/formative-assessment")}
                    >
                        <CardContent sx={{ zIndex: 2 }}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontFamily="Poppins"
                                color="white"
                                textAlign="center"
                            >
                                FORMATIVE ASSESSMENT
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Summative Assessment Card */}
                <Grid item xs={12} md={5}>
                    <Card 
                        sx={cardStyle(summative)}
                        onClick={() => handleCardClick("/create-assessment/summative-assessment")}
                    >
                        <CardContent sx={{ zIndex: 2 }}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontFamily="Poppins"
                                color="white"
                                textAlign="center"
                            >
                                SUMMATIVE ASSESSMENT
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CreateAssessment;
