import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { TextField, Button, Typography, Container, CircularProgress, Box, useMediaQuery } from '@mui/material';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password);
            navigate('/');
        } catch (err) {
            switch (err.code) {
                case 'auth/wrong-password':
                    setError("Incorrect password.");
                    break;
                case 'auth/user-not-found':
                    setError("No user found with this email.");
                    break;
                default:
                    setError("Login failed. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: isMobile ? 'center' : 'space-between',
                backgroundImage: `url('/resources/login_signup_bg.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                padding: isMobile ? 2 : 0,
                mt: isMobile ? -7 : 0,
            }}
        >
            {!isMobile && (
                <Box
                    sx={{
                        flex: '0 0 50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ml: 5,
                        mt: 5,
                        backgroundImage: `url('/resources/kaiba_logo.png')`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '100%',
                    }}
                />
            )}

            <Container
                maxWidth="xs"
                sx={{
                    backgroundColor: '#FFFFFF',
                    padding: isMobile ? 3 : 4,
                    borderRadius: 2,
                    marginTop: isMobile ? 2 : 0,
                    mr: isMobile ? 4 : 17,
                    width: isMobile ? '90%' : 'auto',
                }}
            >
                <form onSubmit={handleSubmit}>
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{ fontFamily: 'Poppins', marginBottom: 1 }}
                    >
                        Welcome to
                    </Typography>
                    <Typography
                        variant="h3"
                        align="center"
                        sx={{
                            color: '#870400',
                            fontFamily: 'Poppins',
                            fontWeight: 900,
                            marginBottom: 2,
                            letterSpacing: '0.5rem',
                            textShadow: '1px 1px 0px rgba(0,0,0,0.5)',
                        }}
                    >
                        KAIBA
                    </Typography>
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{ fontFamily: 'Poppins', mb: -1, fontWeight: 600 }}
                    >
                        Please enter your personal information
                    </Typography>
                    <TextField
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        name="password"
                        label="Password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                backgroundColor: '#870400',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#6b0300',
                                },
                            }}
                            fullWidth
                        >
                            Login
                        </Button>
                    )}
                </form>

                <Typography
                    variant="body2"
                    align="center"
                    sx={{ marginTop: 2, fontFamily: 'Poppins' }}
                >
                    Don't have an account yet?{" "}
                    <Button
                        onClick={() => navigate('/signup')}
                        sx={{
                            textTransform: 'none',
                            color: 'blue',
                            padding: 0,
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        Sign up here!
                    </Button>
                </Typography>
            </Container>
        </Box>
    );
};

export default Login;
