import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

import artImage from '../res/arts.png';
import hazardMappingImage from '../res/hazard_mapping.png';
import letterImage from '../res/letter.png';
import posterImage from '../res/poster.png';
import sloganImage from '../res/slogan.png';

// Aralin 1
import SA1sanaysay from '../res/SUMMATIVE-ASSESSMENT/LESSON-1/pagsusulat-ng-sanaysay.png';
import SA1debate from '../res/SUMMATIVE-ASSESSMENT/LESSON-1/debate.png';
import SA1research from '../res/SUMMATIVE-ASSESSMENT/LESSON-1/research-project-on-globalization.png';
import SA1poster from '../res/SUMMATIVE-ASSESSMENT/LESSON-1/poster-making.png';
import SA1oralPresentation from '../res/SUMMATIVE-ASSESSMENT/LESSON-1/oral-presentation.png';

// Aralin 2
import SA2posisyongPapel from '../res/SUMMATIVE-ASSESSMENT/LESSON-2/pagsusulat-ng-posisyong-papel.png';
import SA2caseStudyAnalysis from '../res/SUMMATIVE-ASSESSMENT/LESSON-2/case-study-analysis.png';
import SA2multimediaPresentation from '../res/SUMMATIVE-ASSESSMENT/LESSON-2/multimedia-presentation.png';
import SA2debate from '../res/SUMMATIVE-ASSESSMENT/LESSON-2/debate.png';
import SA2infographicCreation from '../res/SUMMATIVE-ASSESSMENT/LESSON-2/infographic-creation.png';

// Aralin 3
import SA3debate from '../res/SUMMATIVE-ASSESSMENT/LESSON-3/debate.png';
import SA3rolePlay from '../res/SUMMATIVE-ASSESSMENT/LESSON-3/role-playing.png';
import SA3caseStudy from '../res/SUMMATIVE-ASSESSMENT/LESSON-3/case-study.png';
import SA3documentaryVideo from '../res/SUMMATIVE-ASSESSMENT/LESSON-3/documentary-video.png';
import SA3researchPaper from '../res/SUMMATIVE-ASSESSMENT/LESSON-3/case-study.png';

// Aralin 4
import SA4pagsusuri from '../res/SUMMATIVE-ASSESSMENT/LESSON-4/pagsusuri-ng-kalagayan-ng-mga-manggagawa.png';
import SA4tinig from '../res/SUMMATIVE-ASSESSMENT/LESSON-4/tinig-ng-manggagawa.png';
import SA4isang from '../res/SUMMATIVE-ASSESSMENT/LESSON-4/isang-pagtingin-sa-mga-bata-sa-paggawa.png';
import SA4pagsasanay from '../res/SUMMATIVE-ASSESSMENT/LESSON-4/pagsasanay-sa-manggagawa.png';
import SA4ipahayag from '../res/SUMMATIVE-ASSESSMENT/LESSON-4/ipahayag-mo-pananaw-mo.png';

// Aralin 5
import SA5videoDocumentary from '../res/SUMMATIVE-ASSESSMENT/LESSON-5/video-documentary.png';
import SA5painting from '../res/SUMMATIVE-ASSESSMENT/LESSON-5/painting.png';
import SA5rolePlay from '../res/SUMMATIVE-ASSESSMENT/LESSON-5/role-play.png';
import SA5laro from '../res/SUMMATIVE-ASSESSMENT/LESSON-5/laro.png';
import SA5musika from '../res/SUMMATIVE-ASSESSMENT/LESSON-5/musika.png';

// Aralin 6
import SA6podcastEp from '../res/SUMMATIVE-ASSESSMENT/LESSON-6/podcast-episode.png';
import SA6culturalExchangeDay from '../res/SUMMATIVE-ASSESSMENT/LESSON-6/cultural-exchange-day.png';
import SA6magazine from '../res/SUMMATIVE-ASSESSMENT/LESSON-6/magazine.png';
import SA6infomercial from '../res/SUMMATIVE-ASSESSMENT/LESSON-6/infomercial.png';
import SA6photoEssay from '../res/SUMMATIVE-ASSESSMENT/LESSON-6/photo-essay.png';

// Aralin 7
import SA7infomercial from '../res/SUMMATIVE-ASSESSMENT/LESSON-7/infomercial.png';
import SA7rolePlay from '../res/SUMMATIVE-ASSESSMENT/LESSON-7/role-play.png';
import SA7advocacyCampaign from '../res/SUMMATIVE-ASSESSMENT/LESSON-7/advocacy-magazine.png';
import SA7actionResearch from '../res/SUMMATIVE-ASSESSMENT/LESSON-7/action-research.png';
import SA7ePortfolio from '../res/SUMMATIVE-ASSESSMENT/LESSON-7/e-portfolio.png';

// Aralin 8
import SA8rolePlay from '../res/SUMMATIVE-ASSESSMENT/LESSON-8/role-play.png';
import SA8trendAnalysis from '../res/SUMMATIVE-ASSESSMENT/LESSON-8/trend-analysis.png';
import SA8resarchPaper from '../res/SUMMATIVE-ASSESSMENT/LESSON-8/research-paper.png';
import SA8debate from '../res/SUMMATIVE-ASSESSMENT/LESSON-8/debate.png';
import SA8scrapbook from '../res/SUMMATIVE-ASSESSMENT/LESSON-8/scrapbook.png';





// Array of Aralin with specific activities
const aralins = [
  {
    id: 1,
    title: "Aralin 1: Kahulugan, Kasaysayan at Dahilan ng Globalisasyon",
    activities: [
      { name: "Pagsusulat ng Sanaysay", image: SA1sanaysay },
      { name: "Debate", image: SA1debate },
      { name: "Research Project on Globalization", image: SA1research },
      { name: "Poster Making", image: SA1poster },
      { name: "Oral Presentation", image: SA1oralPresentation },
    ],
  },
  {
    id: 2,
    title: "Aralin 2: Dimensiyon at Epekto ng Globalisasyon",
    activities: [
      { name: "Pagsulat ng Posisyong Papel", image: SA2posisyongPapel },
      { name: "Case Study Analysis", image: SA2caseStudyAnalysis },
      { name: "Multimedia Presentation", image: SA2multimediaPresentation },
      { name: "Debate", image: SA2debate },
      { name: "Infographic Creation", image: SA2infographicCreation },
    ],
  },
  {
    id: 3,
    title: "Aralin 3: Kalagayan at Suliranin ng Paggawa sa Bansa sa Konteksto ng Globalisasyon",
    activities: [
      { name: '“Boses ng Manggagawa” - (Debate)', image: SA3debate },
      { name: '“Sikaping I-Rescue” - (Role Play)', image: SA3rolePlay },
      { name: '“Isang Pagsusuri” - (Case Study)', image: SA3caseStudy },
      { name: '“Manggagawa sa Bansa” - (Documentary Video)', image: SA3documentaryVideo },
      { name: '"Mga Manggagawa sa Makabagong Panahon” - (Research Paper)', image: SA3researchPaper },
    ],
  },
  {
    id: 4,
    title: "Aralin 4: Pagtugon sa Isyu sa Paggawa sa Bansa",
    activities: [
      { name: '“Pagsusuri ng Kalagayan ng mga Manggagawa”', image: SA4pagsusuri },
      { name: '“Tinig ng Manggagawa”', image: SA4tinig },
      { name: '“Isang Pagtingin sa mga Batas sa Paggawa”', image: SA4isang },
      { name:  '“Pagsasanay sa Manggagawa”', image: SA4pagsasanay },
      { name: '"Ipahayag Mo, Pananaw Mo!"', image: SA4ipahayag },
    ],
  },
  {
    id: 5,
    title: "Aralin 5: Salik at mga Dahilan ng Migrasyon",
    activities: [
      { name: "Video Documentary", image: SA5videoDocumentary },
      { name: "Painting", image: SA5painting },
      { name: "Role Play", image: SA5rolePlay },
      { name: "Laro", image: SA5laro },
      { name: "Musika", image: SA5musika },
    ],
  },
  {
    id: 6,
    title: "Aralin 6: Epekto ng Migrasyon Dulot ng Globalisasyon",
    activities: [
      { name: "Podcast Episode", image: SA6podcastEp },
      { name: "Cultural Exchange Day", image: SA6culturalExchangeDay },
      { name: "Magazine", image: SA6magazine },
      { name: "Infomercial", image: SA6infomercial },
      { name: "Photo Essay", image: SA6photoEssay },
    ],
  },
  {
    id: 7,
    title: "Aralin 7: Epekto ng Globalisasyon sa Aspektong Pang-Ekonomiya, Pangteknolohikal, Panlipunan at Sosyo-Kultural",
    activities: [
      { name: "Info-mercial", image: SA7infomercial },
      { name: "Role-play", image: SA7rolePlay },
      { name: "Advocacy Campaign", image: SA7advocacyCampaign },
      { name: "Action Research", image: SA7actionResearch },
      { name: "E-Portfolio", image: SA7ePortfolio },
    ],
  },
  {
    id: 8,
    title: "Aralin 8: Positibo at Negatibong Saloobin Hinggil sa mga Piling Aspekto ng Globalisasyon",
    activities: [
      { name: "Role play", image: SA8rolePlay },
      { name: "Trend Analysis", image: SA8trendAnalysis },
      { name: "Research Paper", image: SA8resarchPaper },
      { name: "Debate", image: SA8debate },
      { name: "Scrapbook", image: SA8scrapbook },
    ],
  },
  // Add more aralins with unique activities as needed
];

function SummativeAssessment() {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the device is mobile using matchMedia
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 600px)").matches);
    };

    // Initial check and add event listener for window resize
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box sx={{ px: 4, mt: isMobile ? 12 : 14 }}>
      {/* Title */}
      <Typography
        variant="h4"
        component="div"
        fontWeight="bold"
        fontFamily="Poppins"
        textAlign="center"
        mb={2}
      >
        SUMMATIVE ASSESSMENT
      </Typography>

      {/* MGA ARALIN Section */}
      <Typography
        variant="h5"
        component="div"
        fontFamily="Poppins"
        fontWeight="bold"
        textAlign="left"
        mb={1}
      >
        LESSON COVERAGE:
      </Typography>

      {/* Grid for Aralin Buttons */}
      <Grid container spacing={2} justifyContent="center">
        {aralins.map((aralin) => (
          <Grid item xs={12} sm={6} key={aralin.id}>
            <Link
              to={`/create-assessment/summative-assessment/choose-activity/${aralin.id}`}
              state={{ title: aralin.title, aralinId: aralin.id, activities: aralin.activities }}
              style={{ textDecoration: "none" }}
            >
              <Button fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "#323639",
                  color: "white",
                  fontFamily: "Poppins",
                  height: isMobile ? 100 : 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "0 16px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  textTransform: "none",
                  transition: "transform 0.2s, background-color 0.2s",
                  "&:hover": {
                    backgroundColor: "#FF9D01",
                    transform: "scale(1.02)",
                  },
                }}>
                {aralin.title}
              </Button>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SummativeAssessment;
