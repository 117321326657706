import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { auth } from './config/firebase';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import CreateAssessment from './pages/CreateAssessment';
import About from './pages/About';

import FormativeAssessment from './pages/FormativeAssessment';
import FormativeChooseActivity from './pages/FormativeChooseActivity';
import FormativeAssessmentInput from './pages/FormativeAssessmentInput';
import FormativeAssessmentRecords from './pages/FormativeAssessmentRecords';


import SummativeAssessment from './pages/SummativeAssessment';
import SummativeChooseActivity from './pages/SummativeChooseActivity';
import SummativeAssessmentInput from './pages/SummativeAssessmentInput';
import SummativeAssessmentRecords from './pages/SummativeAssessmentRecords';

import SignUp from './pages/Signup';
import Login from './pages/Login';

import ProfileSettings from './pages/ProfileSettings';
import UsersGuide from './pages/UsersGuide';
import PrivateRoute from './PrivateRoute';

function App() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';
  const isSignUpRoute = location.pathname === '/signup';

  useEffect(() => {
    // Set authentication persistence to in-memory only
    setPersistence(auth, browserSessionPersistence).then(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setAuthenticated(!!user);
        setLoading(false);
      });
      return unsubscribe;
    }).catch((error) => {
      console.error("Error setting auth persistence:", error);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex min-h-screen">
      {!isLoginRoute && !isSignUpRoute && <Navbar />}
      <div className={`flex-1 ${isLoginRoute || isSignUpRoute ? 'flex justify-center items-center' : ''}`}>
        <Routes>
          {/* Public Routes */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />

          {/* Redirect to login if unauthenticated */}
          <Route
            path="/"
            element={authenticated ? <Home /> : <Navigate to="/login" replace />}
          />

          {/* Private Routes */}
          <Route path="/profile-settings" element={<PrivateRoute authenticated={authenticated} element={<ProfileSettings />} />} />
          <Route path="/users-guide" element={<PrivateRoute authenticated={authenticated} element={<UsersGuide />} />} />
          <Route path="/create-assessment" element={<PrivateRoute authenticated={authenticated} element={<CreateAssessment />} />} />


          <Route path="/about" element={<PrivateRoute authenticated={authenticated} element={<About />} />} />
          {/* <Route path="/history" element={<PrivateRoute authenticated={authenticated} element={<History />} />} /> */}


          {/* FORMATIVE OR SUMMATIVE */}
          <Route path="/create-assessment/formative-assessment" element={<PrivateRoute authenticated={authenticated} element={<FormativeAssessment />} />} />
          <Route path="/create-assessment/summative-assessment" element={<PrivateRoute authenticated={authenticated} element={<SummativeAssessment />} />} />


          {/* CHOOSE ACTIVITY */}
          <Route path="/create-assessment/formative-assessment/choose-activity/:aralinId" element={<PrivateRoute authenticated={authenticated} element={<FormativeChooseActivity />} />} />
          <Route path="/create-assessment/summative-assessment/choose-activity/:aralinId" element={<PrivateRoute authenticated={authenticated} element={<SummativeChooseActivity />} />} />

          {/* Routes for creating and editing FORMATIVE assessments */}
          <Route
            path="/create-assessment/formative-assessment/input"
            element={<PrivateRoute authenticated={authenticated} element={<FormativeAssessmentInput />} />}
          />
          <Route
            path="/create-assessment/formative-assessment/input/:aralinId/:activityName"
            element={<PrivateRoute authenticated={authenticated} element={<FormativeAssessmentInput />} />}
          />

          {/* Routes for creating and editing SUMMATIVE assessments */}
          <Route
            path="/create-assessment/summative-assessment/input"
            element={<PrivateRoute authenticated={authenticated} element={<SummativeAssessmentInput />} />}
          />
          <Route
            path="/create-assessment/summative-assessment/input/:aralinId/:activityName"
            element={<PrivateRoute authenticated={authenticated} element={<SummativeAssessmentInput />} />}
          />

          {/* ASSESSMENT RECORDS */}
          <Route path="/assessment-records/formative-assessment" element={<PrivateRoute authenticated={authenticated} element={<FormativeAssessmentRecords />} />} />

          <Route path="/assessment-records/summative-assessment" element={<PrivateRoute authenticated={authenticated} element={<SummativeAssessmentRecords />} />} />

        </Routes>

      </div>
    </div>
  );
}

export default App;
