import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

// Aralin 1
import FA1conceptMapping from '../res/FORMATIVE-ASSESSMENT/LESSON-1/concept-mapping.png';
import FA1groupDiscussion from '../res/FORMATIVE-ASSESSMENT/LESSON-1/group-discussion.png';
import FA1timelineCreation from '../res/FORMATIVE-ASSESSMENT/LESSON-1/timeline-creation.png';
import FA1thinkPairShare from '../res/FORMATIVE-ASSESSMENT/LESSON-1/think-pair-share.png';
import FA1jigsaw from '../res/FORMATIVE-ASSESSMENT/LESSON-1/jigsaw-act.png';

// Aralin 2
import FA2groupDiscussion from '../res/FORMATIVE-ASSESSMENT/LESSON-2/group-discussion.png';
import FA2conceptMapping from '../res/FORMATIVE-ASSESSMENT/LESSON-2/concept-mapping.png';
import FA2reflection from '../res/FORMATIVE-ASSESSMENT/LESSON-2/reflection-journal.png';
import FA2miniResearch from '../res/FORMATIVE-ASSESSMENT/LESSON-2/mini-research.png';
import FA2flowChart from '../res/FORMATIVE-ASSESSMENT/LESSON-2/globalization-flow-chart.png';

// Aralin 3

import FA3researchBasedAssessment from '../res/FORMATIVE-ASSESSMENT/LESSON-3/research-based-assessment.png';
import FA3historicalAnalysis from '../res/FORMATIVE-ASSESSMENT/LESSON-3/historical-analysis.png';
import FA3speech from '../res/FORMATIVE-ASSESSMENT/LESSON-3/speech.png';
import FA3artInstallation from '../res/FORMATIVE-ASSESSMENT/LESSON-3/art-installation.png';
import FA3musicalPerf from '../res/FORMATIVE-ASSESSMENT/LESSON-3/musical-perf.png';

// Aralin 4
import FA4humanGraph from '../res/FORMATIVE-ASSESSMENT/LESSON-4/human-graph.png';
import FA4rolePlay from '../res/FORMATIVE-ASSESSMENT/LESSON-4/role-playing.png';
import FA4debate from '../res/FORMATIVE-ASSESSMENT/LESSON-4/debate.png';
import FA4pananaliksik from '../res/FORMATIVE-ASSESSMENT/LESSON-4/pananaliksik.png';
import FA4infographic from '../res/FORMATIVE-ASSESSMENT/LESSON-4/infographic.png';

// Aralin 5
import FA5kanta from '../res/FORMATIVE-ASSESSMENT/LESSON-5/kanta.png';
import FA5poster from '../res/FORMATIVE-ASSESSMENT/LESSON-5/poster.png';
import FA5rolePlay from '../res/FORMATIVE-ASSESSMENT/LESSON-5/role-play.png';
import FA5essay from '../res/FORMATIVE-ASSESSMENT/LESSON-5/essay.png';
import FA5kwentoNgMigrante from '../res/FORMATIVE-ASSESSMENT/LESSON-5/kwento-ng-migrante.png';

// Aralin 6
import FA6balita from '../res/FORMATIVE-ASSESSMENT/LESSON-6/balita.png';
import FA6debate from '../res/FORMATIVE-ASSESSMENT/LESSON-6/debate.png';
import FA6collage from '../res/FORMATIVE-ASSESSMENT/LESSON-6/collage.png';
import FA6blogPost from '../res/FORMATIVE-ASSESSMENT/LESSON-6/blog-post.png';
import FA6infographics from '../res/FORMATIVE-ASSESSMENT/LESSON-6/infographics.png';

// Aralin 7
import FA7poster from '../res/FORMATIVE-ASSESSMENT/LESSON-7/poster.png';
import FA7collage from '../res/FORMATIVE-ASSESSMENT/LESSON-7/photo-collage.png';
import FA7conceptMapping from '../res/FORMATIVE-ASSESSMENT/LESSON-7/concept-mapping.png';
import FA7reflection from '../res/FORMATIVE-ASSESSMENT/LESSON-7/reflection.png';
import FA7slogan from '../res/FORMATIVE-ASSESSMENT/LESSON-7/slogan.png';

// Aralin 8
import FA8infographics from '../res/FORMATIVE-ASSESSMENT/LESSON-8/infographics.png';
import FA8reflection from '../res/FORMATIVE-ASSESSMENT/LESSON-8/relfection.png';
import FA8graphicOrganizer from '../res/FORMATIVE-ASSESSMENT/LESSON-8/graphic-organizer.png';
import FA8pictureAnalysis from '../res/FORMATIVE-ASSESSMENT/LESSON-8/picture-analysis.png';
import FA8articleAnalysis from '../res/FORMATIVE-ASSESSMENT/LESSON-8/article-analysis.png';

// Array of Aralin with specific activities
const aralins = [
  {
    id: 1,
    title: "Aralin 1: Kahulugan, Kasaysayan at Dahilan ng Globalisasyon",
    activities: [
      { name: "Concept Mapping", image: FA1conceptMapping },
      { name: "Group Discussion Reflection", image: FA1groupDiscussion },
      { name: "Timeline Creation", image: FA1timelineCreation },
      { name: "Think-Pair-Share", image: FA1thinkPairShare },
      { name: "Jigsaw Activity: Globalization Themes", image: FA1jigsaw },
    ],
  },
  {
    id: 2,
    title: "Aralin 2: Dimensiyon at Epekto ng Globalisasyon",
    activities: [
      { name: "Group Discussion", image: FA2groupDiscussion },
      { name: "Concept Mapping", image: FA2conceptMapping },
      { name: "Reflection Journal", image: FA2reflection },
      { name: "Mini-Research", image: FA2miniResearch },
      { name: "Globalization Flow Chart: Process and Effects", image: FA2flowChart },
    ],
  },
  {
    id: 3,
    title: "Aralin 3: Kalagayan at Suliranin ng Paggawa sa Bansa sa Konteksto ng Globalisasyon",
    activities: [
      { name: '“Mga Suliranin sa Paggawa” - (Research-Based Assessment)', image: FA3researchBasedAssessment },
      { name: '“Sulyap sa Nakaraan” - (Historical Analysis)', image: FA3historicalAnalysis },
      { name: '“Talumpati ng Pag-asa” - (Speech)', image: FA3speech },
      { name: '“Kahulugan ng Manggagawa” - (Art Installation)', image: FA3artInstallation },
      { name: "“Musika ng Manggagawa” - (Musical Performance)", image: FA3musicalPerf },
    ],
  },
  {
    id: 4,
    title: "Aralin 4: Pagtugon sa Isyu sa Paggawa sa Bansa",
    activities: [
      { name: "Human Graph – Ang Opinyon Ko", image: FA4humanGraph },
      { name: 'Role-Playing – "Boses ng Manggagawa"', image: FA4rolePlay },
      { name: 'Debate – "Usapang Pasahod"', image: FA4debate },
      { name: 'Pananaliksik – "Paggawa at Ekonomiya"', image: FA4pananaliksik },
      { name: 'Infographic – "Isyung Paggawa sa Datos"', image: FA4infographic },
    ],
  },
  {
    id: 5,
    title: "Aralin 5: Salik at mga Dahilan ng Migrasyon",
    activities: [
      { name: "Kanta", image: FA5kanta },
      { name: "Poster", image: FA5poster },
      { name: "Role-Play", image: FA5rolePlay },
      { name: "Essay", image: FA5essay },
      { name: "Kwento ng Migrante", image: FA5kwentoNgMigrante },
    ],
  },
  {
    id: 6,
    title: "Aralin 6: Epekto ng Migrasyon Dulot ng Globalisasyon",
    activities: [
      { name: "Balita", image: FA6balita },
      { name: "Debate", image: FA6debate },
      { name: "Collage", image: FA6collage },
      { name: "Blog Post", image: FA6blogPost },
      { name: "Infographics", image: FA6infographics },
    ],
  },
  {
    id: 7,
    title: "Aralin 7: Epekto ng Globalisasyon sa Aspektong Pang-Ekonomiya, Pangteknolohikal, Panlipunan at Sosyo-Kultural",
    activities: [
      { name: "Poster", image: FA7poster },
      { name: "Photo Collage", image: FA7collage },
      { name: "Concept Mapping", image: FA7conceptMapping },
      { name: "Reflection", image: FA7reflection },
      { name: "Slogan", image: FA7slogan },
    ],
  },
  {
    id: 8,
    title: "Aralin 8: Positibo at Negatibong Saloobin Hinggil sa mga Piling Aspekto ng Globalisasyon",
    activities: [
      { name: "Infographics", image: FA8infographics },
      { name: "Reflection", image: FA8reflection },
      { name: "Graphic Organizer", image: FA8graphicOrganizer },
      { name: "Picture Analysis", image: FA8pictureAnalysis },
      { name: "Article Analysis", image: FA8articleAnalysis },
    ],
  },
  // Add more aralins with unique activities as needed
];


function FormativeAssessment() {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the device is mobile using matchMedia
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 600px)").matches);
    };

    // Initial check and add event listener for window resize
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box sx={{ px: 4, mt: isMobile ? 12 : 14 }}>
      {/* Title */}
      <Typography
        variant="h4"
        component="div"
        fontWeight="bold"
        fontFamily="Poppins"
        textAlign="center"
        mb={2}
      >
        FORMATIVE ASSESSMENT
      </Typography>

      {/* MGA ARALIN Section */}
      <Typography
        variant="h5"
        component="div"
        fontFamily="Poppins"
        fontWeight="bold"
        textAlign="left"
        mb={1}
      >
        LESSON COVERAGE:
      </Typography>

      {/* Grid for Aralin Buttons */}
      <Grid container spacing={2} justifyContent="center">
        {aralins.map((aralin) => (
          <Grid item xs={12} sm={6} key={aralin.id}>
            <Link
              to={`/create-assessment/formative-assessment/choose-activity/${aralin.id}`}
              state={{ title: aralin.title, aralinId: aralin.id, activities: aralin.activities }}
              style={{ textDecoration: "none" }}
            >
              <Button fullWidth
                variant="contained"
                sx={{
                  backgroundColor: "#323639",
                  color: "white",
                  fontFamily: "Poppins",
                  height: isMobile ? 100 : 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "0 16px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  textTransform: "none",
                  transition: "transform 0.2s, background-color 0.2s",
                  "&:hover": {
                    backgroundColor: "#FF9D01",
                    transform: "scale(1.02)",
                  },
                }}>
                {aralin.title}
              </Button>
            </Link>
          </Grid>
        ))}
      </Grid>

    </Box>
  );
}

export default FormativeAssessment;
