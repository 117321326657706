import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Card, CardContent } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom"; // Add useNavigate



function FormativeChooseActivity() {
  const navigate = useNavigate();
  const location = useLocation();
  const { title, aralinId, activities } = location.state || {}; // Get both title and aralinId

  const [isMobile, setIsMobile] = useState(false);

  const handleActivitySelect = (activityName) => {
    navigate(`/create-assessment/formative-assessment/input/`, {
      state: { aralinTitle: title, activityName, aralinId }
    });
  };


  const handleCardClick = (activity) => {
    navigate(`/create-assessment/formative-assessment/input/${aralinId}`, {
      state: { activityName: activity }
    });
  };

  return (
    <Box sx={{ textAlign: 'center', mt: isMobile ? 12 : 14 }}>
      <Typography variant="h4" component="h1" fontWeight="bold" fontFamily="Poppins">
        FORMATIVE ASSESSMENT
      </Typography>

      <Typography variant="h5" component="h2" fontFamily="Poppins" fontWeight="medium" mt={1}>
        (Aralin {aralinId})
      </Typography>

      <Typography variant="h5" fontFamily="Poppins" fontWeight="bold" textAlign="left" mt={3} mb={3} ml={2}>
        LIST OF ACTIVITIES
      </Typography>

      <Grid container spacing={2} justifyContent="center" sx={{ mt: 3 }}>
        {activities.map(({ name, image }) => (
          <Grid item xs={12} sm={2.4} key={name} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              sx={{
                position: 'relative',
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: isMobile ? '80%' : 200,  // Set to fill available space
                width: isMobile ? '80%' : 250, // Ensure width is also controlled
                aspectRatio: isMobile ? '1 / 1' : 'auto', // Maintain square aspect ratio on mobile
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                overflow: 'hidden',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#FF9D0199',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                  zIndex: 1,
                },
                '&:hover::before': {
                  opacity: 1,
                },
              }}
              onClick={() => handleActivitySelect(name)}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h6" fontWeight="bold" fontFamily="Poppins" color="black">
                  {name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default FormativeChooseActivity;
