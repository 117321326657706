// auth.js
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { db, auth } from './config/firebase';
import { collection, query, where, getDocs, doc, setDoc, getDoc } from "firebase/firestore";

// Fetch the current user
export const getCurrentUser = () => auth.currentUser;

// Monitor authentication state
export const onAuthStateChange = (callback) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            callback(user);
        } else {
            callback(null);
        }
    });
};

// Sign up new user and save to Firestore
export const signup = async (userData) => {
    const { email, password, firstName, lastName } = userData;
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await setDoc(doc(db, "teacher", user.uid), { firstName, lastName, email });
    return user;
};

// Log in existing user using username
export const login = async (username, password) => {
    // Fetch user document by username to get email
    const userQuery = query(collection(db, "teacher"), where("username", "==", username));
    const querySnapshot = await getDocs(userQuery);

    if (querySnapshot.empty) {
        throw new Error("User not found");
    }

    const userDoc = querySnapshot.docs[0].data();
    const email = userDoc.email; // Assuming you stored email in Firestore

    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
};

// Log out user
export const logout = async () => signOut(auth);

// Get user details from Firestore
export const getUserDetails = async (uid) => {
    try {
        const teacherCollection = collection(db, 'teacher');
        const q = query(teacherCollection, where('uid', '==', uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            console.log("Document data:", userDoc.data());
            return userDoc.data();
        } else {
            console.log("No user details found for this UID.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching user details:", error);
        return null;
    }
};